import {
  create_user_group,
  get_user_groups,
  get_user_group,
  get_user_group_count,
  toggle_user_group,
  edit_user_group,
  delete_user_group,
  get_user_group_result_excel,
} from "../../apis/modules/userGroup";
import { reIssueFunc } from "../../apis/reissue";
import {
  IUserGroupList,
  IUserGroupEach,
  IUserGroupTarget,
  IUserGroupSearch,
  IUserGroupCount,
  IUserGroupYn,
  IResponsePage,
} from "../../types";

export const getUserGroupsRequest = async (
  fetchData: any
): Promise<IResponsePage<IUserGroupList>> => {
  try {
    const { data } = await get_user_groups(fetchData);
    if (data.statusCode === 200) {
      return {
        content: data.data.content,
        totalElements: data.data.totalElements,
        size: data.data.size,
        totalPages: data.data.totalPages,
        last: data.data.last,
      };
    }
  } catch (error: any) {
    if (error.response?.status === 401) {
      const status = await reIssueFunc();
      if (status === 200) {
        return getUserGroupsRequest(fetchData);
      }
    }
  }
  return {
    content: [],
    totalElements: 0,
    size: 0,
    totalPages: 0,
    last: false,
  };
};

export const createUserGroupRequest = async (): Promise<any> => {
  try {
    const { data } = await create_user_group();
    if (data.statusCode === 200) {
      return data.data;
    }
  } catch (error: any) {
    if (error.response?.status === 401) {
      const status = await reIssueFunc();
      if (status === 200) {
        return createUserGroupRequest();
      }
    }
  }
  return [];
};

export const getUserGroupRequest = async (
  userGroupId: number,
  fetchData: IUserGroupSearch
): Promise<IUserGroupEach<IUserGroupTarget> | undefined> => {
  try {
    const { data } = await get_user_group(userGroupId, fetchData);
    if (data.statusCode === 200) {
      return data.data;
    }
  } catch (error: any) {
    if (error.response?.status === 401) {
      const status = await reIssueFunc();
      if (status === 200) {
        return getUserGroupRequest(userGroupId, fetchData);
      }
    }
  }
  return undefined;
};

export const getUserGroupCountRequest = async (
  userGroupId: number
): Promise<IUserGroupCount> => {
  try {
    const { data } = await get_user_group_count(userGroupId);
    if (data.statusCode === 200) {
      return data.data;
    }
  } catch (error: any) {
    if (error.response?.status === 401) {
      const status = await reIssueFunc();
      if (status === 200) {
        return getUserGroupCountRequest(userGroupId);
      }
    }
  }
  return {
    useAllCnt: 0,
    useYesCnt: 0,
    useNoCnt: 0,
  };
};

export const toggleUserGroupRequest = async (
  userGroupId: number,
  userGroupUseYnRequest: IUserGroupYn[]
): Promise<any> => {
  try {
    const { data } = await toggle_user_group(
      userGroupId,
      userGroupUseYnRequest
    );
    if (data.statusCode === 200) {
      return true;
    }
  } catch (error: any) {
    if (error.response?.status === 401) {
      const status = await reIssueFunc();
      if (status === 200) {
        return toggleUserGroupRequest(userGroupId, userGroupUseYnRequest);
      }
    }
  }
  return undefined;
};

export const editUserGroupRequest = async (
  userGroupId: number,
  fetchData: any
): Promise<any> => {
  try {
    const { data } = await edit_user_group(userGroupId, fetchData);
    if (data.statusCode === 200) {
      return data;
    }
  } catch (error: any) {
    if (error.response?.status === 401) {
      const status = await reIssueFunc();
      if (status === 200) {
        return editUserGroupRequest(userGroupId, fetchData);
      }
    }
  }
  return [];
};

export const deleteUserGroupRequest = async (
  userGroupId: number
): Promise<any> => {
  try {
    const { data } = await delete_user_group(userGroupId);
    if (data.statusCode === 200) {
      return data;
    }
  } catch (error: any) {
    if (error.response?.status === 400) {
      return error?.response?.data;
    }
    if (error.response?.status === 401) {
      const status = await reIssueFunc();
      if (status === 200) {
        return deleteUserGroupRequest(userGroupId);
      }
    }
  }
  return [];
};

export const getUserGroupResultExcelRequest = async (
  userGroupId: number
): Promise<any> => {
  try {
    const data = await get_user_group_result_excel(userGroupId);
    if (data.status === 200) {
      return data;
    }
  } catch (error: any) {
    if (error.response?.status === 401) {
      const status = await reIssueFunc();
      if (status === 200) {
        return getUserGroupResultExcelRequest(userGroupId);
      }
    }
  }
  return {
    content: [],
    totalElements: 0,
    size: 0,
    totalPages: 0,
    last: false,
  };
};
