import React, { useState, useEffect, useRef } from "react";
import JoditReact from "jodit-react";
import "jodit/build/jodit.min.css";

interface JoditEditorProps {
  onChange?: (content: string) => void;
  defaultValue?: string;
  readOnly?: boolean;
}

const JoditEditor: React.FC<JoditEditorProps> = ({
  onChange,
  defaultValue,
  readOnly = false,
}: JoditEditorProps) => {
  const [value, setValue] = useState(defaultValue || null);
  const editor = useRef(null);

  useEffect(() => {
    setValue(defaultValue || "");
  }, [defaultValue]);

  const handleChange = (content: string) => {
    setValue(content);
    if (onChange) {
      onChange(content);
    }
  };

  const config = {
    height: 500,
    readonly: readOnly,
    buttons: [
      "source",
      "|",
      "bold",
      "italic",
      "underline",
      "strikethrough",
      "|",
      "fontsize",
      "brush",
      "paragraph",
      "|",
      "align",
      "undo",
      "redo",
      "hr",
      "table",
      "link",
      "image",
      "ol",
      "ul",
      "indent",
      "outdent",
    ],
    uploader: {
      insertImageAsBase64URI: true,
      insertImage: (file: any, onSuccess: any, onError: any) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          onSuccess(reader.result);
        };
        reader.onerror = (error) => {
          onError(error);
        };
      },
    },
    resize: {
      image: true,
    },
    extraButtons: [
      {
        name: "Insert Button",
        exec: (editor: any) => {
          const existingLink = editor.editor.querySelector("a");
          if (!existingLink) {
            const buttonHTML = `
              <style>
              .new-button {
                display: inline-block;
                background-color: #4a90e2;
                color: #fff;
                border: none;
                padding: 10px 20px;
                border-radius: 5px;
                cursor: pointer;
              }
              .new-button:hover {
                background-color: #3a7fd5;
              }
              </style>
             
              <a href="#" class="new-button">버튼 이름을 변경하세요</a>
            `;
            editor.s.insertHTML(buttonHTML);
          } else {
            alert("버튼이 이미 존재합니다.");
          }
        },
      },
    ],
  };

  return (
    <>
      <style>
        {`
          .jodit-editor__resize {
            display: none !important;
          }
        `}
      </style>
      {/* @ts-ignore */}
      <JoditReact
        config={config}
        onBlur={handleChange}
        value={defaultValue || ""}
      />
    </>
  );
};

export default JoditEditor;
