import styled from "styled-components";

export const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: ${(props) => props.theme.gray.light};
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 35px 16px 16px;
  width: 740px;
  height: 460px;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(22, 25, 43, 0.2);
  background-color: #fff;
`;

export const LoginImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 35px;
  width: 354px;
  height: 100%;
`;

export const LoginImageTop = styled.div`
  width: 100%;
  flex-grow: 1;
  background-color: ${(props) => props.theme.gray.light};
`;

export const LoginImageBottom = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 140px;
  font-size: 28px;
  font-weight: 700;
  color: #fff;
  background-color: ${(props) => props.theme.blue};
`;

export const InfoWrapper = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  height: 100%;
`;

export const InfoTitle = styled.span`
  margin-top: 60px;
  margin-bottom: 16px;
  font-size: 24px;
  font-weight: 700;
`;

export const InfoInputs = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 8px;
`;

export const InfoSelect = styled.select`
  padding-left: 12px;
  margin-bottom: 16px;
  height: 56px;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.gray.main};
`;

export const InfoInput = styled.input`
  padding-left: 12px;
  margin-bottom: 16px;
  height: 56px;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.gray.main};
`;

export const InfoButton = styled.button`
  margin-top: 28px;
  width: 100%;
  height: 48px;
  font-weight: 700;
  border-radius: 24px;
  background-color: ${(props) => props.theme.gray.main};
`;
