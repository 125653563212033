import { useState } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Chip from "../../components/Chip";
import Button from "../../components/Button";
import InputText from "../../components/InputText";
import { IconX } from "@tabler/icons-react";
import { motion, AnimatePresence } from "framer-motion";
import {
  Table,
  TableHeader,
  TableCell,
  ScrollableTable,
} from "../../components/TableSlot";
import { IAdminAccountsBase, IAdminAccounts } from "../../types";
import {
  getEhrAccountsRequest,
  addAdminAccountsRequest,
} from "../../utils/hooks/adminAccount";

const Overlay = styled(motion.div)`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  margin-left: -40px;
`;

const Modal = styled.div`
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  width: 600px;
  height: 500px;
  box-shadow: 0 2px 6px 0 rgba(29, 29, 31, 0.16);
  background-color: #fff;
  border-radius: 12px;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 45px;
  padding: 15px 20px 10px;
  border-bottom: 1px solid ${(props) => props.theme.gray.main};
  font-weight: 700;
`;

const ModalContent = styled.div`
  width: 100%;
  height: calc(100% - 115px);
  padding: 15px 20px 10px;
`;

const ModalSelectedGroup = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  margin-bottom: 8px;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 70px;
  padding: 15px 20px 10px;
  border-top: 1px solid ${(props) => props.theme.gray.main};

  & > button:not(:last-child) {
    margin-right: 16px;
  }
`;

function CreateAdminManage() {
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [ehrData, setEhrData] = useState<IAdminAccountsBase[]>([]);
  const [chipData, setChipData] = useState<IAdminAccounts[]>([]);
  const createAdminMatch = useMatch("/adminManage/create");
  const navigate = useNavigate();

  const onOverlayClick = () => {
    navigate(`/adminManage`);
  };

  const addChip = (row: any) => {
    if (chipData.length > 0) {
      alert("한 번에 한 명만 추가할 수 있습니다.");
      return;
    }
    setChipData((prevData) => [...prevData, row]);
  };

  const removeChip = (row: any) => {
    setChipData((prevChipData) =>
      prevChipData.filter((item) => item.empId !== row.empId)
    );
  };

  const handleSearch = async () => {
    try {
      const result = await getEhrAccountsRequest(searchKeyword);
      if (result) {
        setEhrData(result);
      }
    } catch (error) {
      console.error("Error fetching ehr data:", error);
    }
  };

  const search = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && searchKeyword.trim() !== "") {
      handleSearch();
    }
    if (event.key === "Enter" && searchKeyword.trim() === "") {
      return;
    }
  };

  const saveAdminAccount = async () => {
    if (chipData.length !== 0) {
      const { empId, empNm, email, password, corpCd, corpNm, orgId, orgNm } =
        chipData[0];
      const newObject = {
        empId,
        empNm,
        email,
        password,
        corpCd,
        corpNm,
        orgId,
        orgNm,
      };
      try {
        const result = await addAdminAccountsRequest(newObject);
        if (result.statusCode === 400) {
          alert(`${result.errorCode}`);
          return;
        }
        if (result.statusCode === 200) {
          navigate("/adminManage");
        }
      } catch (error) {
        console.error("Error fetching ehr data:", error);
      }
    } else {
      alert("계정을 선택해주세요.");
      return;
    }
  };

  return (
    <AnimatePresence>
      {createAdminMatch ? (
        <>
          <Overlay
            onClick={onOverlayClick}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          />
          <Modal>
            <ModalHeader>
              <span>계정 추가</span>
              <IconX stroke={2} size={16} onClick={onOverlayClick} />
            </ModalHeader>

            <ModalContent>
              <ModalSelectedGroup>
                <span>선택 계정:</span>
                {chipData.map((item: IAdminAccountsBase) => (
                  <Chip
                    key={item.empId}
                    text={item.empNm}
                    onClose={() => removeChip(item)}
                  />
                ))}
              </ModalSelectedGroup>

              <InputText
                placeholder="사번 또는 이름을 입력하세요"
                value={searchKeyword}
                onChange={(e) => setSearchKeyword(e.target.value)}
                onKeyDown={search}
              />

              <ScrollableTable height="250px">
                <Table>
                  <thead>
                    <tr
                      style={{
                        position: "sticky",
                        top: "0",
                        zIndex: "100",
                        backgroundColor: "#fff",
                      }}
                    >
                      <TableHeader>소속</TableHeader>
                      <TableHeader>사번</TableHeader>
                      <TableHeader>이름</TableHeader>
                    </tr>
                  </thead>
                  <tbody>
                    {ehrData?.map((row) => (
                      <tr key={row.empId} onClick={() => addChip(row)}>
                        <TableCell>
                          {row.corpNm}_{row.orgNm}
                        </TableCell>
                        <TableCell>{row.empId}</TableCell>
                        <TableCell>{row.empNm}</TableCell>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </ScrollableTable>
            </ModalContent>

            <ModalFooter>
              <Button
                className="filled"
                $bgColor="#e4e4e4"
                $hoverBgColor="#f4f4f4"
                color="#000"
                onClick={onOverlayClick}
              >
                닫기
              </Button>
              <Button
                className="filled"
                $bgColor="#0c51af"
                $hoverBgColor="#007bff"
                onClick={saveAdminAccount}
              >
                저장
              </Button>
            </ModalFooter>
          </Modal>
        </>
      ) : null}
    </AnimatePresence>
  );
}

export default CreateAdminManage;
