import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import Button from "../../components/Button";
import InputText from "../../components/InputText";
import Loading from "../../components/Loading";
import {
  TableTop,
  SearchContainer,
  Table,
  TableHeader,
  TableCell,
} from "../../components/TableSlot";
import Pagination from "../../components/Pagination";
import { ICampaign, IResponsePage } from "../../types";
import { getCampaignsRequest } from "../../utils/hooks/campaign";

function Campaign() {
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const initialPage = parseInt(searchParams.get("page") || "1", 10);
  const [currentPage, setCurrentPage] = useState<number>(initialPage);
  const fetchData = {
    keyword: searchKeyword,
    page: currentPage - 1,
    size: 10,
    sort: [""],
  };
  const {
    data: campaigns = {
      content: [],
      totalElements: 0,
      size: 0,
      totalPages: 0,
      last: false,
    },
    isLoading,
    refetch,
  } = useQuery<IResponsePage<ICampaign>, Error>(
    ["campaignData", searchKeyword, currentPage],
    () => getCampaignsRequest(fetchData),
    { keepPreviousData: true }
  );

  const onCreateClick = () => {
    navigate(`/campaign/create`);
  };

  const onShowResult = (id: number) => {
    navigate(`/campaign/result/${id}?page=${currentPage}`);
  };
  // const onShowResult = (id: number, round: number) => {
  //   navigate(`/campaign/result/${id}?page=${currentPage}&round=${round}`);
  // };

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKeyword(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleSearch = async () => {
    try {
      setCurrentPage(1);
      await refetch();
    } catch (error) {
      console.error("Error fetching template data:", error);
    }
  };

  const handleRowClick = (id: number) => {
    navigate(`/campaign/edit/${id}?page=${currentPage}`);
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    navigate(`/campaign?page=${pageNumber}`);
  };

  useEffect(() => {
    // 페이지가 로드될 때마다 데이터를 새로 불러옵니다.
    refetch();
  }, [currentPage, refetch]);

  return (
    <>
      {isLoading ? (
        <Loading message="페이지를 불러오는 중입니다..." />
      ) : (
        <>
          <TableTop>
            {/* 조건 검색 */}
            <SearchContainer>
              <InputText
                placeholder="캠페인 명을 입력하세요"
                width={"250px"}
                type="text"
                value={searchKeyword}
                onChange={handleSearchInputChange}
                onKeyDown={handleKeyDown}
              />
              {/* <Button
                className="outlined"
                $borderColor="#0c51af"
                onClick={handleSearch}
              >
                검색
              </Button> */}
            </SearchContainer>
            <Button
              className="filled"
              $bgColor="#0c51af"
              $hoverBgColor="#007bff"
              onClick={onCreateClick}
            >
              등록
            </Button>
          </TableTop>

          <Table style={{ padding: "16px" }}>
            <thead>
              <tr>
                <TableHeader width="5%">번호</TableHeader>
                <TableHeader width="20%">캠페인 명</TableHeader>
                <TableHeader width="20%">템플릿 명</TableHeader>
                <TableHeader width="10%">시작일자</TableHeader>
                <TableHeader width="10%">종료일자</TableHeader>
                {/* <TableHeader width="5%">차수</TableHeader> */}
                <TableHeader width="10%">응답/대상 인원</TableHeader>
                <TableHeader width="15%">상태</TableHeader>
              </tr>
            </thead>
            <tbody>
              {campaigns?.content?.map((row: ICampaign, index: number) => (
                <tr key={index} onClick={() => handleRowClick(row.campaignId)}>
                  <TableCell width="5%">
                    {campaigns.totalElements -
                      ((currentPage - 1) * fetchData.size + index)}
                  </TableCell>
                  <TableCell width="20%">{row.campaignName}</TableCell>
                  <TableCell width="20%">{row.templateName}</TableCell>
                  <TableCell width="10%">
                    {row.startDate.slice(0, -9)}
                  </TableCell>
                  <TableCell width="10%">{row.endDate.slice(0, -9)}</TableCell>
                  {/* <TableCell width="5%">
                    {row.roundCnt === 0 ? "-" : row.roundCnt + "차"}
                  </TableCell> */}
                  <TableCell width="10%">
                    {row.responseCnt}명 / {row.totalCnt}명
                  </TableCell>
                  <TableCell
                    width="15%"
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {row.status}
                    <Button
                      className="outlined"
                      onClick={(e) => {
                        e.stopPropagation();
                        onShowResult(row.campaignId);
                        // onShowResult(row.campaignId, row.roundCnt);
                      }}
                      style={{ marginLeft: "8px" }}
                    >
                      현황조회 {row.resultStatus}
                    </Button>
                  </TableCell>
                </tr>
              ))}
            </tbody>
          </Table>

          <Pagination
            page={currentPage}
            totalPageCount={campaigns.totalPages}
            pageDisplayCount={campaigns.size}
            onChange={handlePageChange}
          />
        </>
      )}
    </>
  );
}

export default Campaign;
