import { useState, useEffect } from "react";
import { useLocation, useMatch, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import styled from "styled-components";
import Button from "../../components/Button";
import InputText from "../../components/InputText";
import CreateAdminManage from "./AddAdminAccount";
import Loading from "../../components/Loading";
import {
  TableTop,
  SearchContainer,
  Table,
  TableHeader,
  TableCell,
} from "../../components/TableSlot";
import Pagination from "../../components/Pagination";
import { IAdminAccounts, IResponsePage } from "../../types";
import {
  getAdminAccountsRequest,
  deleteAdminAccountRequest,
} from "../../utils/hooks/adminAccount";

const AdminManageContainer = styled.div`
  width: 100%;
  height: 100%;
`;

function AdminManage() {
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const navigate = useNavigate();
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const fetchData = {
    keyword: searchKeyword,
    page: currentPage - 1,
    size: 10,
    sort: [""],
  };
  const {
    data: adminAccountsData = {
      content: [],
      totalElements: 0,
      size: 0,
      totalPages: 0,
      last: false,
    },
    isLoading,
    refetch,
  } = useQuery<IResponsePage<IAdminAccounts>, Error>(
    ["adminAccountData", searchKeyword, currentPage],
    () => getAdminAccountsRequest(fetchData),
    { keepPreviousData: true }
  );

  const onCreateClick = () => {
    navigate(`/adminManage/create`, { state: adminAccountsData.content });
  };

  const handleSearch = async () => {
    try {
      setCurrentPage(1);
      await refetch();
    } catch (error) {
      console.error("Error fetching admin data:", error);
    }
  };

  const createAdminMatch = useMatch("/adminManage/create");

  const onDeleteRow = async (adminId: number) => {
    const isConfirmed = window.confirm(
      "관리자 계정 목록에서 삭제하시겠습니까?"
    );
    if (isConfirmed) {
      const { data } = await deleteAdminAccountRequest(adminId);
      if (data) {
        refetch();
      }
    }
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    refetch();
  }, [currentPage, location, refetch]);

  return (
    <>
      {isLoading ? (
        <Loading message="페이지를 불러오는 중입니다..." />
      ) : (
        <AdminManageContainer>
          <TableTop>
            <SearchContainer>
              <InputText
                placeholder="검색어를 입력하세요"
                width={"250px"}
                type="text"
                value={searchKeyword}
                onChange={(e) => setSearchKeyword(e.target.value)}
              />
              {/* <Button
                className="outlined"
                $borderColor="#0c51af"
                onClick={handleSearch}
              >
                검색
              </Button> */}
            </SearchContainer>
            <Button
              className="filled"
              $bgColor="#0c51af"
              $hoverBgColor="#007bff"
              onClick={onCreateClick}
            >
              등록
            </Button>
          </TableTop>

          <Table>
            <thead>
              <tr>
                <TableHeader width="5%">번호</TableHeader>
                <TableHeader width="20%">회사</TableHeader>
                <TableHeader width="25%">부서</TableHeader>
                <TableHeader width="20%">사번</TableHeader>
                <TableHeader width="20%">이름</TableHeader>
                <TableHeader width="10%">삭제</TableHeader>
              </tr>
            </thead>
            <tbody>
              {adminAccountsData?.content?.map(
                (row: IAdminAccounts, index: number) => (
                  <tr key={index}>
                    <TableCell width="5%">
                      {adminAccountsData.totalElements -
                        ((currentPage - 1) * fetchData.size + index)}
                    </TableCell>
                    <TableCell width="20%">{row.corpNm}</TableCell>
                    <TableCell width="25%">{row.orgNm}</TableCell>
                    <TableCell width="20%">{row.empId}</TableCell>
                    <TableCell width="20%">{row.empNm}</TableCell>
                    <TableCell width="10%">
                      <Button
                        className="outlined"
                        onClick={() => onDeleteRow(row.adminId)}
                      >
                        삭제
                      </Button>
                    </TableCell>
                  </tr>
                )
              )}
            </tbody>
          </Table>

          <Pagination
            page={currentPage}
            totalPageCount={adminAccountsData.totalPages}
            pageDisplayCount={adminAccountsData.size}
            onChange={handlePageChange}
          />

          {createAdminMatch ? <CreateAdminManage /> : null}
        </AdminManageContainer>
      )}
    </>
  );
}

export default AdminManage;
