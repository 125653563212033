import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import JoditEditor from "../../components/JoditEditor";
import Button from "../../components/Button";
import InputText from "../../components/InputText";
import {
  BoxContainer,
  BoxTitle,
  BoxContent,
  EachFormWrapper,
  EachFormTitle,
  EachFormContent,
  ErrorMessage,
  ButtonWrapper,
} from "../../components/BoxSlot";
import { ITemplateFormData } from "../../types";
import { createTemplateRequest } from "../../utils/hooks/template";

function CreateTemplate() {
  const mainDefaultValue = `
    <style>
    .container {
      background-color: #fff;
      border-radius: 10px;
      padding: 20px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      text-align: center;
      width: 100%;
    }
    .notice-image {
      max-width: 100%;
      height: auto;
    }
    .notice-text {
      margin-top: 20px;
    }
    .notice-button {
      display: inline-block;
      margin-top: 10px;
      padding: 10px 20px;
      background-color: #ff6b6b;
      color: #fff;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      text-decoration: none;
    }
    .notice-button:hover {
      background-color: #ff5e5e;
    }
    img {
      max-width: 100%;
      height: auto;
    }
    </style>

    <div class="container">
      <img src="image.png" alt="공지 이미지" class="notice-image" />
      <div class="notice-text">
        <p>안녕하세요.</p>
        <p>공지사항 내용이 들어갈 자리입니다.</p>
        <p>더 자세한 내용을 원하시면 아래 버튼을 클릭해 주세요.</p>
      </div>
      <a href="#" class="notice-button">공지사항 확인</a>
    </div>
  `;
  const detailDefaultValue = `
    <style>
    .wrapper {
      width: 100%;
      min-height: 240px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .login-container {
      background-color: #fff;
      border-radius: 10px;
      padding: 20px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      text-align: center;
    }
    .logo-image {
      max-width: 100%;
      height: auto;
    }
    .login-form {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .login-form input {
      width: 260px;
      height: 40px;
      padding: 10px;
      margin-bottom: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
    }
    .login-form input[type="password"] {
      margin-bottom: 20px;
    }
    .login-button {
      display: inline-block;
      width: 260px;
      margin-top: 20px;
      padding: 10px 20px;
      background-color: #4a90e2;
      color: #fff;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      text-decoration: none;
    }
    .login-button:hover {
      background-color: #3a7fd5;
    }
    img {
      max-width: 100%
      height: auto;
    }
    </style>

    <div class="wrapper">
      <div class="login-container">
        <img src="image.png" alt="로고 이미지" class="logo-image" />
        <div>
          <form action="/login" method="post" class="login-form">
            <input type="text" placeholder="아이디" />
            <input type="password" placeholder="비밀번호" />
          </form>
        </div>
        <a href="#" class="login-button">로그인</a>
      </div>
    </div>
  `;

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ITemplateFormData>();

  const [templateMain, setTemplateMain] = useState<string>(mainDefaultValue);
  const [templateDetail, setTemplateDetail] =
    useState<string>(detailDefaultValue);

  const handleValueChange = (newMainContent: string) => {
    setTemplateMain(newMainContent);
  };

  const handleDetailChange = (newDetailContent: string) => {
    setTemplateDetail(newDetailContent);
  };

  const onSubmit = async (data: ITemplateFormData) => {
    const inputData = {
      ...data,
      templateMain,
      templateDetail,
      mailSender: "Alfa-del@aju.co.kr",
    };
    const isConfirmed = window.confirm("새 템플릿을 등록 하시겠습니까?");
    if (isConfirmed) {
      try {
        const result = await createTemplateRequest(inputData);
        if (result) {
          navigate("/template");
        }
      } catch (error) {
        console.error("Error creating template:", error);
      }
    }
  };

  return (
    <BoxContainer>
      <BoxTitle>메일 템플릿 등록</BoxTitle>

      <BoxContent>
        <EachFormWrapper>
          <EachFormTitle>메일 템플릿 제목</EachFormTitle>

          <EachFormContent>
            <InputText
              {...register("templateName", {
                required: "제목은 필수 입력 값입니다.",
              })}
              placeholder="메일 템플릿 제목"
              width={"600px"}
              type="text"
            />
            <ErrorMessage>{errors?.templateName?.message}</ErrorMessage>
          </EachFormContent>
        </EachFormWrapper>

        <EachFormWrapper>
          <EachFormTitle>메일 주소 (보내는 사람)</EachFormTitle>
          <EachFormContent>
            <InputText
              value={"Alfa-del@aju.co.kr"}
              placeholder="메일 주소 (보내는 사람)"
              width={"600px"}
              type="text"
              disabled
            />
          </EachFormContent>
        </EachFormWrapper>

        <EachFormWrapper>
          <EachFormTitle>메일 템플릿 메인</EachFormTitle>
          <EachFormContent>
            <JoditEditor
              onChange={handleValueChange}
              defaultValue={mainDefaultValue}
            />
          </EachFormContent>
        </EachFormWrapper>

        <EachFormWrapper>
          <EachFormTitle>메일 템플릿 상세</EachFormTitle>
          <EachFormContent>
            <JoditEditor
              onChange={handleDetailChange}
              defaultValue={detailDefaultValue}
            />
          </EachFormContent>
        </EachFormWrapper>
      </BoxContent>

      <ButtonWrapper>
        <Button
          className="filled"
          $bgColor="#e4e4e4"
          $hoverBgColor="#f4f4f4"
          color="#000"
          onClick={() => {
            navigate(-1);
          }}
        >
          취소
        </Button>
        <Button
          className="filled"
          $bgColor="#0c51af"
          $hoverBgColor="#007bff"
          onClick={handleSubmit(onSubmit)}
        >
          등록
        </Button>
      </ButtonWrapper>
    </BoxContainer>
  );
}

export default CreateTemplate;
