import { getCookie } from "./cookie";

/**
 * 쿠키의 accessToken 확인하고 HTTP 요청 헤더의 Auth 값으로 부여
 * @param {Object} instance
 * @returns
 */
function setInterceptors(instance: any) {
  instance.interceptors.request.use(
    function (config: any) {
      const accessToken = getCookie("accessToken");
      config.headers["Authorization"] = `Bearer ${accessToken}`;
      return config;
    },

    function (error: any) {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    function (response: any) {
      return response;
    },

    function (error: any) {
      return Promise.reject(error);
    }
  );

  return instance;
}

export { setInterceptors };
