import React from "react";
import styled from "styled-components";
import { IconXboxXFilled } from "@tabler/icons-react";

interface ChipProps {
  text: string;
  onClose?: () => void; // 닫기 이벤트 핸들러는 선택적으로 받음
}

const ChipContainer = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 0 12px;
  border-radius: 20px;
  border: 1px solid #e0e0e0;
  margin-left: 8px;
  height: 32px;
`;

const ChipText = styled.span`
  margin-right: 5px;
`;

// Chip 컴포넌트
const Chip: React.FC<ChipProps> = ({ text, onClose }) => {
  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <ChipContainer>
      <ChipText>{text}</ChipText>
      {/* {onClose && <CloseIcon onClick={handleClose} />} */}
      {onClose && <IconXboxXFilled size={18} onClick={handleClose} />}
    </ChipContainer>
  );
};

export default Chip;
