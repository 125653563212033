import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import InputText from "../../components/InputText";
import Select from "../../components/Select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/locale";
import dayjs from "dayjs";
import {
  BoxContainer,
  BoxTitle,
  BoxContent,
  EachFormWrapper,
  EachFormTitle,
  EachFormContent,
  ButtonWrapper,
  ErrorMessage,
} from "../../components/BoxSlot";
// import Loading from "../../components/Loading";
import JoditEditor from "../../components/JoditEditor";
import {
  ICampaignFormData,
  ITemplate,
  IUserGroupList,
  IResponsePage,
} from "../../types";
import {
  getTemplatesRequest,
  getTemplateRequest,
} from "../../utils/hooks/template";
import { getUserGroupsRequest } from "../../utils/hooks/userGroup";
import { createCampaignRequest } from "../../utils/hooks/campaign";
import { domainArr } from "../../utils/domain";

function CreateCampaign() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ICampaignFormData>();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const fetchData = {
    keyword: "",
    page: 0,
    size: 2000,
    sort: [""],
  };
  const { data: templates } = useQuery<IResponsePage<ITemplate>>(
    "getTemplateData",
    () => getTemplatesRequest(fetchData)
  );
  const { data: userGroups } = useQuery<IResponsePage<IUserGroupList>>(
    "userGroupData",
    () => getUserGroupsRequest(fetchData)
  );

  const onSubmit = async (data: ICampaignFormData) => {
    const inputData = {
      userGroupId: +data.userGroupId,
      campaignName: data.campaignName,
      startDate: dayjs(startDate).format("YYYY-MM-DD") + "T00:00:00",
      endDate: dayjs(endDate).format("YYYY-MM-DD") + "T23:59:59",
      templateId: +data.templateId,
      mailSender,
      templateMain,
      templateDetail,
      campaignMailDomain: data.campaignMailDomain,
    };
    const isConfirmed = window.confirm("새 캠페인을 등록 하시겠습니까?");
    if (isConfirmed) {
      try {
        const result = await createCampaignRequest(inputData);
        if (result) {
          navigate("/campaign");
        }
      } catch (error) {
        console.error("Error creating template:", error);
      }
    }
  };

  const [templateId, setTemplateId] = useState("");
  const [mailSender] = useState<string>("Alfa-del@aju.co.kr");
  const [templateMain, setTemplateMain] = useState<string>("");
  const [templateDetail, setTemplateDetail] = useState<string>("");

  const handleTemplateChange = (e: any) => {
    setTemplateId(e.target.value);
  };

  const {
    data: selectedTemplate,
    isLoading: selectedTemplateLoading,
    refetch: selectedTemplateRefetch,
  } = useQuery<ITemplate | undefined>(
    ["selectedTemplate", templateId],
    () => getTemplateRequest(Number(templateId)),
    { enabled: false, refetchOnMount: false }
  );

  useEffect(() => {
    if (templateId) {
      selectedTemplateRefetch();
    }
  }, [templateId, selectedTemplateRefetch]);

  useEffect(() => {
    if (selectedTemplate) {
      setTemplateMain(selectedTemplate.templateMain || "");
      setTemplateDetail(selectedTemplate.templateDetail || "");
    }
  }, [selectedTemplate]);

  const handleValueChange = (newMainContent: string) => {
    setTemplateMain(newMainContent);
  };

  const handleDetailChange = (newDetailContent: string) => {
    setTemplateDetail(newDetailContent);
  };

  return (
    // <>
    //   {selectedTemplateLoading ? (
    //     <Loading message="데이터를 불러오는 중입니다..." />
    //   ) : (
    <BoxContainer>
      <BoxTitle>캠페인 등록</BoxTitle>

      <BoxContent>
        <EachFormWrapper>
          <EachFormTitle>캠페인 제목</EachFormTitle>

          <EachFormContent>
            <InputText
              {...register("campaignName", {
                required: "제목은 필수입니다.",
              })}
              placeholder="캠페인 제목"
              width={"600px"}
              type="text"
            />
            {errors.campaignName && (
              <ErrorMessage>{errors.campaignName.message}</ErrorMessage>
            )}
          </EachFormContent>
        </EachFormWrapper>

        <EachFormWrapper>
          <EachFormTitle>도메인 선택</EachFormTitle>

          <EachFormContent>
            <form>
              <Select
                {...register("campaignMailDomain", {
                  required: "도메인 선택은 필수입니다.",
                })}
                width="600px"
                // onChange={handleTemplateChange}
              >
                <option value="">선택하세요</option>
                {domainArr.map((domain: any, index: number) => (
                  <option key={index} value={domain.value}>
                    {domain.name}
                  </option>
                ))}
              </Select>
            </form>
            {errors.campaignMailDomain && (
              <ErrorMessage>{errors.campaignMailDomain.message}</ErrorMessage>
            )}
          </EachFormContent>
        </EachFormWrapper>

        <EachFormWrapper>
          <EachFormTitle>메일 템플릿 선택</EachFormTitle>

          <EachFormContent>
            <form>
              <Select
                {...register("templateId", {
                  required: "템플릿 선택은 필수입니다.",
                })}
                width="600px"
                onChange={handleTemplateChange}
              >
                <option value="">선택하세요</option>
                {templates?.content?.map((row: ITemplate, index: number) => (
                  <option key={index} value={row.templateId}>
                    {row.templateName}
                  </option>
                ))}
              </Select>
            </form>
            {errors.templateId && (
              <ErrorMessage>{errors.templateId.message}</ErrorMessage>
            )}
          </EachFormContent>
        </EachFormWrapper>

        <EachFormWrapper>
          <EachFormTitle>캠페인 기간</EachFormTitle>
          <EachFormContent>
            <div style={{ display: "flex" }}>
              <DatePicker
                selected={startDate}
                onChange={(date: Date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                locale={ko}
                dateFormat="yyyy-MM-dd"
                customInput={
                  <InputText placeholder="시작일자" width={"200px"} />
                }
              />
              <DatePicker
                selected={endDate}
                onChange={(date: Date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                locale={ko}
                dateFormat="yyyy-MM-dd"
                customInput={
                  <InputText placeholder="종료일자" width={"200px"} />
                }
              />
            </div>
          </EachFormContent>
        </EachFormWrapper>

        <EachFormWrapper>
          <EachFormTitle>훈련 대상 검색</EachFormTitle>
          <EachFormContent>
            <form>
              <Select
                {...register("userGroupId", {
                  required: "훈련 대상 선택은 필수입니다.",
                })}
                width="600px"
                onChange={(e) =>
                  setValue("userGroupId", parseInt(e.target.value))
                }
              >
                <option value="">선택하세요</option>
                {userGroups?.content?.map(
                  (row: IUserGroupList, index: number) => (
                    <option key={index} value={row.userGroupId}>
                      {row.userGroupName}
                    </option>
                  )
                )}
              </Select>
            </form>
            {errors.userGroupId && (
              <ErrorMessage>{errors.userGroupId.message}</ErrorMessage>
            )}
          </EachFormContent>
        </EachFormWrapper>

        <EachFormWrapper>
          <EachFormTitle>메일 주소 (보내는 사람)</EachFormTitle>
          <EachFormContent>
            <InputText
              value={mailSender}
              placeholder="메일 주소 (보내는 사람)"
              width={"600px"}
              type="text"
              disabled
            />
          </EachFormContent>
        </EachFormWrapper>

        <EachFormWrapper>
          <EachFormTitle>메일 템플릿 메인</EachFormTitle>
          <EachFormContent>
            <JoditEditor
              defaultValue={templateMain}
              onChange={handleValueChange}
            />
          </EachFormContent>
        </EachFormWrapper>

        <EachFormWrapper>
          <EachFormTitle>메일 템플릿 상세</EachFormTitle>
          <EachFormContent>
            <JoditEditor
              defaultValue={templateDetail}
              onChange={handleDetailChange}
            />
          </EachFormContent>
        </EachFormWrapper>

        <EachFormWrapper>
          <EachFormTitle>테스트 메일 주소</EachFormTitle>
          <EachFormContent>
            <InputText
              placeholder="캠페인 등록 후 수정 페이지에서 발송 가능합니다"
              width={"600px"}
              type="text"
              disabled
            />
            <Button className="filled" disabled>
              테스트 발송
            </Button>
          </EachFormContent>
        </EachFormWrapper>
      </BoxContent>

      <ButtonWrapper>
        <Button
          className="filled"
          $bgColor="#e4e4e4"
          $hoverBgColor="#f4f4f4"
          color="#000"
          onClick={() => {
            navigate(-1);
          }}
        >
          취소
        </Button>
        <Button
          className="filled"
          $bgColor="#0c51af"
          $hoverBgColor="#007bff"
          onClick={handleSubmit(onSubmit)}
        >
          등록
        </Button>
      </ButtonWrapper>
    </BoxContainer>
    //   )}
    // </>
  );
}

export default CreateCampaign;
