import styled from "styled-components";
import {
  IconChevronLeft,
  IconChevronRight,
  IconChevronsLeft,
  IconChevronsRight,
} from "@tabler/icons-react";

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px 16px;
`;

const PaginationButton = styled.button`
  margin: 0 5px;
  width: 24px;
  height: 24px;
  background-color: transparent;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  transition: color 0.3s;

  &.disabled {
    color: grey;
    cursor: not-allowed;
  }

  &.active {
    background-color: ${(props) => props.theme.blue};
    color: #fff;
    font-weight: bold;
  }
`;

interface PaginationProps {
  page: number;
  totalPageCount: number;
  pageDisplayCount: number;
  onChange: (pageNumber: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  page,
  totalPageCount,
  pageDisplayCount,
  onChange,
}) => {
  const currentPageGroup = Math.ceil(page / pageDisplayCount);
  const lastPageNumber =
    currentPageGroup * pageDisplayCount > totalPageCount
      ? totalPageCount
      : currentPageGroup * pageDisplayCount;
  const firstPageNumber = Math.max(lastPageNumber - pageDisplayCount + 1, 1);

  const pageList = Array.from(
    { length: Math.min(pageDisplayCount, totalPageCount) },
    (_, i) => firstPageNumber + i
  );

  const nextButtonDisabled = lastPageNumber >= totalPageCount;
  const nextSingleButtonDisabled = page === totalPageCount;
  const previousSingleButtonDisabled = page === 1;
  const previousButtonDisabled = firstPageNumber <= 1;

  const change = (clickNumber: number) => {
    if (clickNumber === page) return;
    onChange(clickNumber);
  };

  const previous = () => {
    if (!previousButtonDisabled) {
      onChange(firstPageNumber - pageDisplayCount);
    }
  };

  const previousSingle = () => {
    if (!previousSingleButtonDisabled) {
      onChange(page - 1);
    }
  };

  const next = () => {
    if (!nextButtonDisabled) {
      onChange(lastPageNumber + 1);
    }
  };

  const nextSingle = () => {
    if (!nextSingleButtonDisabled) {
      onChange(page + 1);
    }
  };

  return (
    <PaginationWrapper>
      <PaginationButton
        className={`pagination-button ${
          previousButtonDisabled ? "disabled" : ""
        }`}
        onClick={previous}
        disabled={previousButtonDisabled}
      >
        <IconChevronsLeft />
      </PaginationButton>

      <PaginationButton
        className={`pagination-button ${
          previousSingleButtonDisabled ? "disabled" : ""
        }`}
        onClick={previousSingle}
        disabled={previousSingleButtonDisabled}
      >
        <IconChevronLeft />
      </PaginationButton>

      {pageList.map((number) => (
        <PaginationButton
          key={number}
          className={`pagination-button ${page === number ? "active" : ""}`}
          onClick={() => change(number)}
        >
          {number}
        </PaginationButton>
      ))}

      <PaginationButton
        className={`pagination-button ${
          nextSingleButtonDisabled ? "disabled" : ""
        }`}
        onClick={nextSingle}
        disabled={nextSingleButtonDisabled}
      >
        <IconChevronRight />
      </PaginationButton>

      <PaginationButton
        className={`pagination-button ${nextButtonDisabled ? "disabled" : ""}`}
        onClick={next}
        disabled={nextButtonDisabled}
      >
        <IconChevronsRight />
      </PaginationButton>
    </PaginationWrapper>
  );
};

export default Pagination;
