import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  LoginContainer,
  InputWrapper,
  LoginImageWrapper,
  LoginImageTop,
  LoginImageBottom,
  InfoWrapper,
  InfoTitle,
  InfoInputs,
  InfoInput,
  InfoButton,
} from "../styles/Login.styles";
import { ILogin } from "../types";
import { loginRequest } from "../utils/hooks/login";
import { ErrorMessage } from "../components/BoxSlot";

function Login() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ILogin>();
  const navigate = useNavigate();

  const onSubmit = async (data: ILogin) => {
    try {
      const result = await loginRequest(data);
      if (result) {
        const accessToken = result.jwt.accessToken;
        const refreshToken = result.jwt.refreshToken;
        const userInfo = result.admin;

        document.cookie = `accessToken=${accessToken}`;
        document.cookie = `refreshToken=${refreshToken}`;
        window.localStorage.setItem("userInfo", JSON.stringify(userInfo));

        navigate("/");
      }
    } catch (error) {
      console.error("Error login:", error);
      alert("로그인 실패: 아이디 또는 비밀번호가 잘못되었습니다.");
    }
  };

  return (
    <LoginContainer>
      <InputWrapper>
        <LoginImageWrapper>
          <LoginImageTop />
          <LoginImageBottom>피싱 메일 관리 시스템</LoginImageBottom>
        </LoginImageWrapper>

        <InfoWrapper>
          <InfoTitle>Log IN</InfoTitle>

          <InfoInputs>
            <InfoInput
              {...register("adminId", {
                required: "사번을 입력하세요",
                minLength: {
                  value: 5,
                  message: "아이디는 5자 이상이어야 합니다.",
                },
              })}
              type="text"
              placeholder="ID"
            />
            <ErrorMessage>{errors?.adminId?.message}</ErrorMessage>
            <InfoInput
              {...register("password", {
                required: "비밀번호를 입력하세요",
                minLength: {
                  value: 3,
                  message: "비밀번호는 3자 이상이어야 합니다.",
                },
              })}
              type="password"
              placeholder="Password"
            />
            <ErrorMessage>{errors?.password?.message}</ErrorMessage>
          </InfoInputs>
          <InfoButton onClick={handleSubmit(onSubmit)}>로그인</InfoButton>
        </InfoWrapper>
      </InputWrapper>
    </LoginContainer>
  );
}

export default Login;
