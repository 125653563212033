import {
  create_campaign,
  get_campaigns,
  get_campaign,
  edit_campaign,
  delete_campaign,
  get_campaign_current,
  add_campaign_round,
  send_campaign_mail,
  get_campaign_current_excel,
  send_campaign_test_mail,
  copy_user_group,
} from "../../apis/modules/campaign";
import { reIssueFunc } from "../../apis/reissue";
import {
  ICampaign,
  ICampaignBase,
  ICampaignCurrent,
  ICampaignCurrentList,
  IResponsePage,
  Row,
} from "../../types";

export const getCampaignsRequest = async (
  fetchData: any
): Promise<IResponsePage<ICampaign>> => {
  try {
    const { data } = await get_campaigns(fetchData);
    if (data.statusCode === 200) {
      return {
        content: data.data.content,
        totalElements: data.data.totalElements,
        size: data.data.size,
        totalPages: data.data.totalPages,
        last: data.data.last,
      };
    }
  } catch (error: any) {
    if (error.response?.status === 401) {
      const status = await reIssueFunc();
      if (status === 200) {
        return getCampaignsRequest(fetchData);
      }
    }
  }
  return {
    content: [],
    totalElements: 0,
    size: 0,
    totalPages: 0,
    last: false,
  };
};

export const createCampaignRequest = async (
  inputData: ICampaignBase
): Promise<any> => {
  try {
    const { data } = await create_campaign(inputData);
    if (data.statusCode === 200) {
      return data.data;
    }
  } catch (error: any) {
    if (error.response?.status === 401) {
      const status = await reIssueFunc();
      if (status === 200) {
        return createCampaignRequest(inputData);
      }
    }
  }
  return [];
};

export const getCampaignRequest = async (
  campaignId: number
): Promise<ICampaign | undefined> => {
  try {
    const { data } = await get_campaign(campaignId);
    if (data.statusCode === 200) {
      return data.data;
    }
  } catch (error: any) {
    if (error.response?.status === 401) {
      const status = await reIssueFunc();
      if (status === 200) {
        return getCampaignRequest(campaignId);
      }
    }
  }
  return undefined;
};

export const editCampaignRequest = async (
  campaignId: number,
  inputData: any
): Promise<any> => {
  try {
    const { data } = await edit_campaign(campaignId, inputData);
    if (data.statusCode === 200) {
      return data;
    }
  } catch (error: any) {
    if (error.response?.status === 401) {
      const status = await reIssueFunc();
      if (status === 200) {
        return editCampaignRequest(campaignId, inputData);
      }
    }
  }
  return [];
};

export const deleteCampaignRequest = async (
  campaignId: number
): Promise<any> => {
  try {
    const { data } = await delete_campaign(campaignId);
    if (data.statusCode === 200) {
      return data;
    }
  } catch (error: any) {
    if (error.response?.status === 401) {
      const status = await reIssueFunc();
      if (status === 200) {
        return deleteCampaignRequest(campaignId);
      }
    }
  }
  return [];
};

export const getCampaignCurrentRequest = async (
  campaignId: number,
  fetchData: any
): Promise<ICampaignCurrent<ICampaignCurrentList> | undefined> => {
  try {
    const { data } = await get_campaign_current(campaignId, fetchData);
    if (data.statusCode === 200) {
      return data.data;
    }
  } catch (error: any) {
    if (error.response?.status === 401) {
      const status = await reIssueFunc();
      if (status === 200) {
        return getCampaignCurrentRequest(campaignId, fetchData);
      }
    }
  }
  return undefined;
};

export const addCampaignRoundRequest = async (
  campaignId: number,
  targetData: Row[]
): Promise<any> => {
  try {
    const { data } = await add_campaign_round(campaignId, targetData);
    if (data.statusCode === 200) {
      return data.statusCode;
    }
  } catch (error: any) {
    if (error.response?.status === 400) {
      throw new Error(error.response.data.data.errorCode);
    }
    if (error.response?.status === 401) {
      const status = await reIssueFunc();
      if (status === 200) {
        return addCampaignRoundRequest(campaignId, targetData);
      }
    }
  }
  return [];
};

export const sendCampaignMailRequest = async (
  campaignId: number,
  campaignRoundNum: number,
  targetData: Row[]
): Promise<any> => {
  try {
    const { data } = await send_campaign_mail(
      campaignId,
      campaignRoundNum,
      targetData
    );
    if (data.statusCode === 200) {
      return data.statusCode;
    }
  } catch (error: any) {
    if (error.response?.status === 401) {
      const status = await reIssueFunc();
      if (status === 200) {
        return sendCampaignMailRequest(
          campaignId,
          campaignRoundNum,
          targetData
        );
      }
    }
  }
  return [];
};

export const getCampaignCurrentExcelRequest = async (
  campaignId: number,
  campaignRoundNum: number
): Promise<any> => {
  try {
    const data = await get_campaign_current_excel(campaignId, campaignRoundNum);
    if (data.status === 200) {
      return data;
    }
  } catch (error: any) {
    if (error.response?.status === 401) {
      const status = await reIssueFunc();
      if (status === 200) {
        return getCampaignCurrentExcelRequest(campaignId, campaignRoundNum);
      }
    }
  }
  return {
    content: [],
    totalElements: 0,
    size: 0,
    totalPages: 0,
    last: false,
  };
};

export const sendCampaignTestMailRequest = async (
  campaignId: number,
  recipientAddress: string
): Promise<any> => {
  try {
    const { data } = await send_campaign_test_mail(
      campaignId,
      recipientAddress
    );
    if (data.statusCode === 200) {
      return data.statusCode;
    }
  } catch (error: any) {
    if (error.response?.status === 401) {
      const status = await reIssueFunc();
      if (status === 200) {
        return sendCampaignTestMailRequest(campaignId, recipientAddress);
      }
    }
  }
  return [];
};

export const copyUserGroupRequest = async (targetData: Row[]): Promise<any> => {
  try {
    const { data } = await copy_user_group(targetData);
    if (data.statusCode === 200) {
      return data.statusCode;
    }
  } catch (error: any) {
    if (error.response?.status === 401) {
      const status = await reIssueFunc();
      if (status === 200) {
        return copyUserGroupRequest(targetData);
      }
    }
  }
  return [];
};
