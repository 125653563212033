import React, { forwardRef } from "react";
import styled from "styled-components";

interface StyledInputProps {
  width?: string | number;
}

const StyledInput = styled.input<StyledInputProps>`
  width: ${(props) => props.width || "100%"};
  height: 40px;
  padding: 8px 12px;
  border: 1px solid ${(props) => props.theme.gray.main};
  border-radius: 4px;
  font-size: 16px;
  margin-right: 16px;
  outline: none;

  &:focus {
    border-color: ${(props) => props.theme.black.tertiary};
  }
`;

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  width?: string | number;
}

const InputText = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const { width, ...rest } = props;
  return <StyledInput ref={ref} width={width} {...rest} />;
});

export default InputText;
