import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import Button from "../../components/Button";
import InputText from "../../components/InputText";
import Loading from "../../components/Loading";
import {
  TableTop,
  SearchContainer,
  Table,
  TableHeader,
  TableCell,
} from "../../components/TableSlot";
import Pagination from "../../components/Pagination";
import { ITemplate, IResponsePage } from "../../types";
import { getTemplatesRequest } from "../../utils/hooks/template";

function Template() {
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const initialPage = parseInt(searchParams.get("page") || "1", 10);
  const [currentPage, setCurrentPage] = useState<number>(initialPage);

  const fetchData = {
    keyword: searchKeyword,
    page: currentPage - 1,
    size: 10,
    sort: [""],
  };

  const {
    data: templates = {
      content: [],
      totalElements: 0,
      size: 0,
      totalPages: 0,
      last: false,
    },
    isLoading,
    refetch,
  } = useQuery<IResponsePage<ITemplate>, Error>(
    ["templateData", searchKeyword, currentPage],
    () => getTemplatesRequest(fetchData),
    { keepPreviousData: true }
  );

  const onCreateClick = () => {
    navigate(`/template/create`);
  };

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchKeyword(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleSearch = async () => {
    try {
      setCurrentPage(1);
      await refetch();
    } catch (error) {
      console.error("Error fetching template data:", error);
    }
  };

  const handleRowClick = (id: number) => {
    navigate(`/template/edit/${id}?page=${currentPage}`);
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    navigate(`/template?page=${pageNumber}`);
  };

  useEffect(() => {
    refetch();
  }, [currentPage, refetch]);

  return (
    <>
      {isLoading ? (
        <Loading message="페이지를 불러오는 중입니다..." />
      ) : (
        <>
          <TableTop>
            <SearchContainer>
              <InputText
                placeholder="템플릿 명을 입력하세요"
                width={"250px"}
                type="text"
                value={searchKeyword}
                onChange={handleSearchInputChange}
                onKeyDown={handleKeyDown}
              />
              {/* <Button
                className="outlined"
                $borderColor="#0c51af"
                onClick={handleSearch}
              >
                검색
              </Button> */}
            </SearchContainer>
            <Button
              className="filled"
              $bgColor="#0c51af"
              $hoverBgColor="#007bff"
              onClick={onCreateClick}
            >
              등록
            </Button>
          </TableTop>

          <Table>
            <thead>
              <tr>
                <TableHeader width="5%">번호</TableHeader>
                <TableHeader width="25%">메일 템플릿 명</TableHeader>
                <TableHeader width="15%">생성자</TableHeader>
                <TableHeader width="20%">생성일자</TableHeader>
                <TableHeader width="15%">수정자</TableHeader>
                <TableHeader width="20%">수정일자</TableHeader>
              </tr>
            </thead>
            <tbody>
              {templates?.content?.map((row: ITemplate, index: number) => (
                <tr key={index} onClick={() => handleRowClick(row.templateId)}>
                  <TableCell width="5%">
                    {templates.totalElements -
                      ((currentPage - 1) * fetchData.size + index)}
                  </TableCell>
                  <TableCell width="25%">{row.templateName}</TableCell>
                  <TableCell width="15%">{row.createdId}</TableCell>
                  <TableCell width="20%">
                    {row.createdDate?.slice(0, -9)}
                  </TableCell>
                  <TableCell width="15%">{row.modifiedId}</TableCell>
                  <TableCell width="20%">
                    {row.modifiedDate?.slice(0, -9)}
                  </TableCell>
                </tr>
              ))}
            </tbody>
          </Table>

          <Pagination
            page={currentPage}
            totalPageCount={templates.totalPages}
            pageDisplayCount={templates.size}
            onChange={handlePageChange}
          />
        </>
      )}
    </>
  );
}

export default Template;
