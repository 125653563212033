import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Pretendard';
    src: url('/fonts/Pretendard-Regular.ttf');
    font-weight: 500;
  }
 
  @font-face {
    font-family: 'Poetsen One';
    src: url('/fonts/PoetsenOne-Regular.ttf');
    font-weight: 500;
  }

  body {
    font-family: "Pretendard", sans-serif;
    color: ${(props) => props.theme.black.primary};
    background-color: #fff;
    line-height: 1.2;
  }
`;
