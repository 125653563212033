import styled from "styled-components";

interface ButtonProps {
  type?: "button" | "submit" | "reset";
  $bgColor?: string;
  color?: string;
  $borderColor?: string;
  $hoverBgColor?: string;
  disabled?: boolean;
}

const Button = styled.button<ButtonProps>`
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  &.outlined {
    background-color: "#ffffff";
    border: 2px solid ${(props) => props.$borderColor || "#4a4a4b"};
    color: ${(props) => props.$borderColor || "#4a4a4b"};

    &:hover {
      background-color: ${(props) =>
        !props.disabled && (props.$hoverBgColor || "transparent")};
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.6;
      background-color: #f5f5f5;
      border: 2px solid #dcdcdc;
      color: #dcdcdc;
    }
  }

  &.filled {
    background-color: ${(props) => props.$bgColor || "#4a4a4b"};
    border: 2px solid ${(props) => props.$bgColor || "#4a4a4b"};
    color: ${(props) => props.color || "#ffffff"};

    &:hover {
      background-color: ${(props) =>
        !props.disabled && (props.$hoverBgColor || "#1d1d1f")};
      border: 2px solid transparent;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.6;
      background-color: ${(props) =>
        props.$bgColor ? `${props.$bgColor}99` : "#4a4a4b99"};
      border: 2px solid
        ${(props) =>
          props.$borderColor ? `${props.$borderColor}99` : "#4a4a4b99"};
      color: ${(props) => (props.color ? `${props.color}99` : "#ffffff99")};
    }
  }
`;

export default Button;
