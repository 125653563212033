import {
  create_template,
  get_templates,
  get_template,
  edit_template,
  delete_template,
} from "../../apis/modules/template";
import { reIssueFunc } from "../../apis/reissue";
import { ITemplateBase, ITemplate, IResponsePage } from "../../types";

export const getTemplatesRequest = async (
  fetchData: any
): Promise<IResponsePage<ITemplate>> => {
  try {
    const { data } = await get_templates(fetchData);
    if (data.statusCode === 200) {
      return {
        content: data.data.content,
        totalElements: data.data.totalElements,
        size: data.data.size,
        totalPages: data.data.totalPages,
        last: data.data.last,
      };
    }
  } catch (error: any) {
    if (error.response?.status === 401) {
      const status = await reIssueFunc();
      if (status === 200) {
        return getTemplatesRequest(fetchData);
      }
    }
  }
  return {
    content: [],
    totalElements: 0,
    size: 0,
    totalPages: 0,
    last: false,
  };
};

export const createTemplateRequest = async (
  inputData: ITemplateBase
): Promise<any> => {
  try {
    const { data } = await create_template(inputData);
    if (data.statusCode === 200) {
      return data.data;
    }
  } catch (error: any) {
    if (error.response?.status === 401) {
      const status = await reIssueFunc();
      if (status === 200) {
        return createTemplateRequest(inputData);
      }
    }
  }
  return [];
};

export const getTemplateRequest = async (
  templateId: number
): Promise<ITemplate | undefined> => {
  try {
    const { data } = await get_template(templateId);
    if (data.statusCode === 200) {
      return data.data;
    }
  } catch (error: any) {
    if (error.response?.status === 401) {
      const status = await reIssueFunc();
      if (status === 200) {
        return getTemplateRequest(templateId);
      }
    }
  }
  return undefined;
};

export const editTemplateRequest = async (
  templateId: number,
  inputData: any
): Promise<any> => {
  try {
    const { data } = await edit_template(templateId, inputData);
    if (data.statusCode === 200) {
      return data;
    }
  } catch (error: any) {
    if (error.response?.status === 401) {
      const status = await reIssueFunc();
      if (status === 200) {
        return editTemplateRequest(templateId, inputData);
      }
    }
  }
  return [];
};

export const deleteTemplateRequest = async (
  templateId: number
): Promise<any> => {
  try {
    const { data } = await delete_template(templateId);
    if (data.statusCode === 200) {
      return data;
    }
  } catch (error: any) {
    if (error.response?.status === 400) {
      return error?.response?.data;
    }
    if (error.response?.status === 401) {
      const status = await reIssueFunc();
      if (status === 200) {
        return deleteTemplateRequest(templateId);
      }
    }
  }
  return [];
};
