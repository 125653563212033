import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import ButtonGroup from "../../components/ButtonGroup";
import Button from "../../components/Button";
import Select from "../../components/Select";
import InputText from "../../components/InputText";
import Loading from "../../components/Loading";
import {
  BoxContainer,
  BoxTitle,
  BoxContent,
  EachFormWrapper,
  EachFormTitle,
  EachFormContent,
  ButtonWrapper,
} from "../../components/BoxSlot";
import {
  TableTop,
  SearchContainer,
  Table,
  TableHeader,
  TableCell,
  ScrollableTable,
} from "../../components/TableSlot";
import { ICampaignCurrent, ICampaignCurrentList, Row } from "../../types";
import {
  getCampaignCurrentRequest,
  addCampaignRoundRequest,
  sendCampaignMailRequest,
  getCampaignCurrentExcelRequest,
  copyUserGroupRequest,
} from "../../utils/hooks/campaign";

const ResultCount = styled.span`
  color: ${(props) => props.theme.red};
  font-weight: 700;
  margin-left: 4px;
`;

const CustomCheckbox = styled.input`
  width: 20px;
  height: 20px;
  position: relative;
  z-index: 1;
  margin-left: 8px;
  margin-right: 8px;
`;

function CampaignResult() {
  const { campaignId } = useParams();
  const [searchParams] = useSearchParams();
  const currentPage = searchParams.get("page") || "1";
  const round = searchParams.get("round") || 0;

  const [corpNmKeyword, setCorpNmKeyword] = useState<string>("");
  const [comNmKeyword, setComNmKeyword] = useState<string>("");
  const [orgNmKeyword, setOrgNmKeyword] = useState<string>("");
  const [dutyNmKeyword, setDutyNmKeyword] = useState<string>("");
  const [empNmKeyword, setEmpNmKeyword] = useState<string>("");
  const [sendYn, setSendYn] = useState<string>("");
  const [mailOpenYn, setMailOpenYn] = useState<string>("");
  const [linkOpenYn, setLinkOpenYn] = useState<string>("");
  const [writeYn, setWriteYn] = useState<string>("");
  const [targetArray, setTargetArray] = useState<Row[]>([]);
  const [allChecked, setAllChecked] = useState<boolean | undefined>(false);
  const [filteredData, setFilteredData] = useState<ICampaignCurrentList[]>([]);
  // const [campaignRoundNum, setCampaignRoundNum] = useState<number>(0);
  const [isMailSent, setIsMailSent] = useState<boolean>(false);

  const navigate = useNavigate();
  const fetchData = {
    empNmKeyword,
    dutyNmKeyword,
    corpNmKeyword,
    comNmKeyword,
    orgNmKeyword,
    sendYn,
    mailOpenYn,
    linkOpenYn,
    writeYn,
    campaignRoundNum: 0,
  };
  const {
    data: campaignCurrentInfo,
    isLoading,
    refetch: refetchResult,
  } = useQuery<ICampaignCurrent<ICampaignCurrentList> | undefined>(
    ["campaignData", campaignId],
    () => getCampaignCurrentRequest(Number(campaignId), fetchData),
    { enabled: false, refetchOnMount: false }
  );

  useEffect(() => {
    refetchResult();
  }, [campaignId, sendYn, mailOpenYn, linkOpenYn, writeYn, refetchResult]);

  const [selectedOptions, setSelectedOptions] = useState(new Array(4).fill(""));

  const handleOptionSelect = (option: string, isSelected: boolean) => {
    const newValue = isSelected ? "" : "Y";

    switch (option) {
      case "sendYn":
        setSendYn(newValue);
        break;
      case "mailOpenYn":
        setMailOpenYn(newValue);
        break;
      case "linkOpenYn":
        setLinkOpenYn(newValue);
        break;
      case "writeYn":
        setWriteYn(newValue);
        break;
      default:
        break;
    }

    const updatedOptions = isSelected
      ? selectedOptions.filter((opt) => opt !== option)
      : [...selectedOptions, option];
    setSelectedOptions(updatedOptions);
  };

  const splitFileName = (contentDisposition: string) => {
    const fileName = contentDisposition.split(";").filter((ele) => {
      return ele.indexOf("filename") > -1;
    })[0];

    if (fileName) {
      const encodedFileName = fileName.split("''")[1];
      return decodeURIComponent(encodedFileName);
    }

    return "default.xlsx";
  };

  const handleExcelExport = async () => {
    const res = await getCampaignCurrentExcelRequest(Number(campaignId), 1);

    const fileNameWithKeyValue = splitFileName(
      res.headers["content-disposition"]
    );
    const fileName = fileNameWithKeyValue || "default.xlsx";

    const blob = new Blob([res.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const link = document.createElement("a");
    const blobUrl = URL.createObjectURL(blob);
    link.href = blobUrl;
    link.download = fileName;
    link.click();
    URL.revokeObjectURL(blobUrl);

    return res.data;
  };

  const handleSearchInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    const value = e.target.value;

    switch (type) {
      case "corpNm":
        setCorpNmKeyword(value);
        break;
      case "comNm":
        setComNmKeyword(value);
        break;
      case "orgNm":
        setOrgNmKeyword(value);
        break;
      case "dutyNm":
        setDutyNmKeyword(value);
        break;
      case "empNm":
        setEmpNmKeyword(value);
        break;
      default:
        break;
    }
  };

  const handleKeyDown = async (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
      await refetchResult();
    }
  };

  const handleToggleAll = (checked: boolean) => {
    if (checked) {
      const allRows =
        campaignCurrentInfo?.campaignCurrentDetail?.map((detail) => ({
          empId: detail.empId,
          empNm: detail.empNm,
          email: detail.email,
          dutyCd: detail.dutyCd,
          dutyNm: detail.dutyNm,
          corpCd: detail.corpCd,
          corpNm: detail.corpNm,
          comCd: detail.comCd,
          comNm: detail.comNm,
          orgId: detail.orgId,
          orgNm: detail.orgNm,
          joinYmd: detail.joinYmd,
        })) || [];
      setTargetArray(allRows);
    } else {
      const searchResults =
        campaignCurrentInfo?.campaignCurrentDetail?.filter((detail) => {
          return (
            (!corpNmKeyword || detail.corpNm.includes(corpNmKeyword)) &&
            (!comNmKeyword || detail.comNm.includes(comNmKeyword)) &&
            (!orgNmKeyword || detail.orgNm.includes(orgNmKeyword)) &&
            (!dutyNmKeyword || detail.dutyNm.includes(dutyNmKeyword)) &&
            (!empNmKeyword || detail.empNm.includes(empNmKeyword))
          );
        }) || [];
      const filteredIds = searchResults.map((detail) => detail.empId);
      const filteredRows = campaignCurrentInfo?.campaignCurrentDetail?.filter(
        (detail) => !filteredIds.includes(detail.empId)
      );
      setTargetArray(filteredRows || []);
    }
  };

  const handleToggle = (target: ICampaignCurrentList, isChecked: boolean) => {
    const {
      empId,
      empNm,
      email,
      dutyCd,
      dutyNm,
      corpCd,
      corpNm,
      comCd,
      comNm,
      orgId,
      orgNm,
      joinYmd,
    } = target;
    const newObject = {
      empId,
      empNm,
      email,
      dutyCd,
      dutyNm,
      corpCd,
      corpNm,
      comCd,
      comNm,
      orgId,
      orgNm,
      joinYmd,
    };
    if (isChecked) {
      setTargetArray((prevArray) => [...prevArray, newObject]);
    } else {
      setTargetArray((prevArray) =>
        prevArray.filter((item) => item.empId !== empId)
      );
    }
  };

  useEffect(() => {
    const allRowsSelected = campaignCurrentInfo?.campaignCurrentDetail?.every(
      (row) => targetArray.some((item) => item.empId === row.empId)
    );
    setAllChecked(allRowsSelected);
  }, [targetArray, campaignCurrentInfo]);

  const handleSendMail = async () => {
    const isConfirmed = window.confirm(`메일을 발송하시겠습니까?`);
    // const isConfirmed = window.confirm(
    //   `${
    //     round === "0" ? "최초" : campaignRoundNum + "차"
    //   } 메일을 발송하시겠습니까?`
    // );

    // let roundToSend = campaignRoundNum;
    // if (campaignRoundNum === 0) {
    //   roundToSend = 1;
    // }

    if (isConfirmed) {
      const result = await sendCampaignMailRequest(
        Number(campaignId),
        1,
        targetArray
      );
      if (result === 200) {
        alert("메일 발송이 완료되었습니다.");
        setIsMailSent(true);
        refetchResult();
      }
    }
  };

  // const handleAddRound = async () => {
  //   try {
  //     const resultCode = await addCampaignRoundRequest(
  //       Number(campaignId),
  //       targetArray
  //     );
  //     if (resultCode === 200) {
  //       refetch();
  //       alert("차수가 추가되었습니다.");
  //     }
  //   } catch (error: any) {
  //     if (error.message) {
  //       alert("차수 추가는 2차까지 가능합니다.");
  //     } else {
  //       console.error("Error adding round:", error);
  //     }
  //   }
  // };

  const isRowInTargetArray = (row: Row) => {
    return targetArray.some((item) => item.empId === row.empId);
  };

  // const handleRoundChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
  //   const newRoundNum = Number(e.target.value);
  //   setCampaignRoundNum(newRoundNum);
  //   fetchData.campaignRoundNum = newRoundNum;
  //   await refetch();
  // };

  // useEffect(() => {
  //   if (round) {
  //     setCampaignRoundNum(Number(round));
  //   }
  // }, [searchParams]);

  useEffect(() => {
    if (campaignCurrentInfo?.campaignCurrentDetail) {
      const initialTargetArray = campaignCurrentInfo.campaignCurrentDetail.map(
        (detail) => ({
          empId: detail.empId,
          empNm: detail.empNm,
          email: detail.email,
          dutyCd: detail.dutyCd,
          dutyNm: detail.dutyNm,
          corpCd: detail.corpCd,
          corpNm: detail.corpNm,
          comCd: detail.comCd,
          comNm: detail.comNm,
          orgId: detail.orgId,
          orgNm: detail.orgNm,
          joinYmd: detail.joinYmd,
        })
      );
      setTargetArray(initialTargetArray);
    }
  }, [campaignCurrentInfo]);

  useEffect(() => {
    if (campaignCurrentInfo?.campaignCurrentDetail) {
      const searchResults =
        campaignCurrentInfo?.campaignCurrentDetail?.filter((detail) => {
          return (
            (!corpNmKeyword || detail.corpNm.includes(corpNmKeyword)) &&
            (!comNmKeyword || detail.comNm.includes(comNmKeyword)) &&
            (!orgNmKeyword || detail.orgNm.includes(orgNmKeyword)) &&
            (!dutyNmKeyword || detail.dutyNm.includes(dutyNmKeyword)) &&
            (!empNmKeyword || detail.empNm.includes(empNmKeyword))
          );
        }) || [];
      setFilteredData(searchResults);
    }
  }, [campaignCurrentInfo]);

  const handleOptionChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
    setState: React.Dispatch<React.SetStateAction<string>>
  ) => {
    setState(event.target.value);
  };

  const copyUserGroup = async () => {
    const isConfirmed = window.confirm(`사용자 그룹을 복사 하시겠습니까?`);
    if (isConfirmed) {
      if (targetArray.length === 0) {
        alert("사용자는 최소 1명 이상이어야 합니다.");
        return;
      }
      const result = await copyUserGroupRequest(targetArray);
      if (result === 200) {
        alert("사용자 그룹 복사가 완료되었습니다.");
        refetchResult();
      }
    }
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <BoxContainer>
          <BoxTitle>캠페인 현황</BoxTitle>

          <BoxContent>
            <Button
              className="outlined"
              onClick={handleExcelExport}
              disabled={campaignCurrentInfo?.totalCnt === 0 && !isMailSent}
              style={{ position: "absolute", top: 40, right: 40 }}
            >
              캠페인 결과 엑셀 다운로드
            </Button>

            <Button
              className="outlined"
              $borderColor="#0c51af"
              onClick={copyUserGroup}
              style={{ position: "absolute", top: 240, right: 40 }}
            >
              사용자 그룹 복사
            </Button>

            <EachFormWrapper>
              <EachFormTitle>캠페인 제목</EachFormTitle>
              <EachFormContent>
                {campaignCurrentInfo?.campaignName}
              </EachFormContent>
            </EachFormWrapper>

            <EachFormWrapper>
              <EachFormTitle>캠페인 기간</EachFormTitle>
              <EachFormContent>
                {campaignCurrentInfo?.startDate.slice(0, -9)} ~{" "}
                {campaignCurrentInfo?.endDate.slice(0, -9)}
              </EachFormContent>
            </EachFormWrapper>

            {/* <EachFormWrapper>
              <EachFormTitle>차수 관리</EachFormTitle>
              <EachFormContent>
                <Select
                  width="150px"
                  value={campaignRoundNum}
                  onChange={handleRoundChange}
                >
                  {campaignCurrentInfo?.campaignRound?.map((round: number) => (
                    <option key={round} value={round}>
                      {round === 0 ? "-" : round + "차"}
                    </option>
                  ))}
                </Select>

                <Button
                  className="filled"
                  style={{ marginRight: "8px" }}
                  onClick={handleAddRound}
                  disabled={campaignRoundNum === 0 && !isMailSent}
                >
                  차수 추가
                </Button>
                <Button
                  className="outlined"
                  onClick={handleExcelExport}
                  disabled={campaignRoundNum === 0 && !isMailSent}
                >
                  차수 엑셀 다운로드
                </Button>
                {campaignRoundNum === 0 && !isMailSent && (
                  <ResultCount>최초 메일 발송을 해주세요</ResultCount>
                )}
              </EachFormContent>
            </EachFormWrapper> */}

            <EachFormWrapper>
              <EachFormTitle>
                캠페인 결과 요약 (전체: {campaignCurrentInfo?.totalCnt}명)
              </EachFormTitle>
              <EachFormContent>
                메일OPEN:
                <ResultCount>
                  {campaignCurrentInfo?.mailOpenCnt}명
                </ResultCount>{" "}
                | 링크OPEN:
                <ResultCount>
                  {campaignCurrentInfo?.linkOpenCnt}명
                </ResultCount>{" "}
                | 개인정보입력:
                <ResultCount>{campaignCurrentInfo?.writeCnt}명</ResultCount>
              </EachFormContent>
            </EachFormWrapper>

            <EachFormWrapper>
              <EachFormTitle>캠페인 결과 표</EachFormTitle>
              <EachFormContent>
                <TableTop>
                  <SearchContainer>
                    <InputText
                      placeholder="회사명"
                      width="150px"
                      type="text"
                      value={corpNmKeyword}
                      onChange={(e) => handleSearchInputChange(e, "corpNm")}
                      onKeyDown={handleKeyDown}
                    />
                    <InputText
                      placeholder="본부명"
                      width="150px"
                      type="text"
                      value={comNmKeyword}
                      onChange={(e) => handleSearchInputChange(e, "comNm")}
                      onKeyDown={handleKeyDown}
                    />
                    <InputText
                      placeholder="부서명"
                      width="150px"
                      type="text"
                      value={orgNmKeyword}
                      onChange={(e) => handleSearchInputChange(e, "orgNm")}
                      onKeyDown={handleKeyDown}
                    />
                    <InputText
                      placeholder="직책"
                      width="150px"
                      type="text"
                      value={dutyNmKeyword}
                      onChange={(e) => handleSearchInputChange(e, "dutyNm")}
                      onKeyDown={handleKeyDown}
                    />
                    <InputText
                      placeholder="사원명"
                      width="150px"
                      type="text"
                      value={empNmKeyword}
                      onChange={(e) => handleSearchInputChange(e, "empNm")}
                      onKeyDown={handleKeyDown}
                    />
                  </SearchContainer>

                  {/* 버튼 그룹 검색 대신 콤보박스 */}
                  <SearchContainer>
                    <Select
                      value={mailOpenYn}
                      maxWidth="150px"
                      onChange={(e) => handleOptionChange(e, setMailOpenYn)}
                    >
                      {[
                        { value: "", label: "메일 전체" },
                        { value: "Y", label: "메일 열람" },
                        { value: "N", label: "메일 미열람" },
                      ].map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Select>
                    <Select
                      value={linkOpenYn}
                      maxWidth="150px"
                      onChange={(e) => handleOptionChange(e, setLinkOpenYn)}
                    >
                      {[
                        { value: "", label: "링크 전체" },
                        { value: "Y", label: "링크 오픈" },
                        { value: "N", label: "링크 미오픈" },
                      ].map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Select>
                    <Select
                      value={writeYn}
                      maxWidth="150px"
                      onChange={(e) => handleOptionChange(e, setWriteYn)}
                    >
                      {[
                        { value: "", label: "개인정보입력 전체" },
                        { value: "Y", label: "입력" },
                        { value: "N", label: "미입력" },
                      ].map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Select>
                    <Select
                      value={sendYn}
                      maxWidth="150px"
                      onChange={(e) => handleOptionChange(e, setSendYn)}
                    >
                      {[
                        { value: "", label: "발송여부 전체" },
                        { value: "Y", label: "발송" },
                        { value: "N", label: "미발송" },
                      ].map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Select>
                  </SearchContainer>
                </TableTop>

                <ScrollableTable height="300px">
                  <Table>
                    <thead>
                      <tr
                        style={{
                          position: "sticky",
                          top: "0",
                          zIndex: "100",
                          backgroundColor: "#fff",
                        }}
                      >
                        <TableHeader width="3%">
                          <CustomCheckbox
                            type="checkbox"
                            checked={allChecked}
                            onChange={(e) => handleToggleAll(e.target.checked)}
                          />
                        </TableHeader>
                        <TableHeader width="10%">회사명</TableHeader>
                        <TableHeader width="10%">본부명</TableHeader>
                        <TableHeader width="10%">부서명</TableHeader>
                        <TableHeader width="5%">직책</TableHeader>
                        <TableHeader width="5%">사원명</TableHeader>
                        <TableHeader width="7%">이메일</TableHeader>
                        <TableHeader width="12.5%">메일OPEN</TableHeader>
                        <TableHeader width="12.5%">링크OPEN</TableHeader>
                        <TableHeader width="12.5%">개인정보입력</TableHeader>
                        <TableHeader width="12.5%">발송시간/내용</TableHeader>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredData.map(
                        (row: ICampaignCurrentList, index: number) => (
                          <tr key={index}>
                            <TableCell width="3%">
                              <CustomCheckbox
                                type="checkbox"
                                checked={isRowInTargetArray(row)}
                                onChange={(e) =>
                                  handleToggle(row, e.target.checked)
                                }
                              />
                            </TableCell>
                            <TableCell width="10%">{row.corpNm}</TableCell>
                            <TableCell width="10%">{row.comNm}</TableCell>
                            <TableCell width="10%">{row.orgNm}</TableCell>
                            <TableCell width="5%">{row.dutyNm}</TableCell>
                            <TableCell width="5%">{row.empNm}</TableCell>
                            <TableCell width="7%">{row.email}</TableCell>
                            <TableCell width="12.5%">
                              {row.mailOpenYn}
                              {row.mailOpenYn === "Y"
                                ? " / " + row.mailOpenDevice
                                : null}
                              <br />
                              {row.mailOpenYn === "Y" ? row.mailOpenDate : null}
                            </TableCell>
                            <TableCell width="12.5%">
                              {row.linkOpenYn}
                              {row.linkOpenYn === "Y"
                                ? " / " + row.linkOpenDevice
                                : null}
                              <br />
                              {row.linkOpenYn === "Y" ? row.linkOpenDate : null}
                            </TableCell>
                            <TableCell width="12.5%">
                              {row.writeYn}
                              {row.writeYn === "Y"
                                ? " / " + row.writeDevice
                                : null}
                              <br />
                              {row.writeYn === "Y" ? row.writeDate : null}
                            </TableCell>
                            <TableCell width="12.5%">
                              {row.exceptionInfo.length === 0
                                ? row.sendDate
                                : row.exceptionInfo}
                            </TableCell>
                          </tr>
                        )
                      )}
                    </tbody>
                  </Table>
                </ScrollableTable>

                <ButtonWrapper>
                  <Button
                    className="filled"
                    $bgColor="#0c51af"
                    $hoverBgColor="#007bff"
                    onClick={handleSendMail}
                  >
                    메일 발송
                  </Button>
                </ButtonWrapper>
              </EachFormContent>
            </EachFormWrapper>
          </BoxContent>

          <ButtonWrapper>
            <Button
              className="filled"
              $bgColor="#e4e4e4"
              $hoverBgColor="#f4f4f4"
              color="#000"
              onClick={(e) => {
                navigate(`/campaign?page=${currentPage}`);
                e.preventDefault();
              }}
            >
              뒤로
            </Button>
          </ButtonWrapper>
        </BoxContainer>
      )}
    </>
  );
}

export default CampaignResult;
