import styled from "styled-components";
import React, { forwardRef } from "react";

interface StyledSelectProps {
  width?: string | number;
  maxWidth?: string | number;
}

const StyledSelect = styled.select<StyledSelectProps>`
  width: ${(props) => props.width || "100%"};
  max-width: ${(props) => props.maxWidth};
  height: 40px;
  padding: 8px 12px;
  border: 1px solid ${(props) => props.theme.gray.main};
  border-radius: 4px;
  font-size: 16px;
  margin-right: 16px;
  outline: none;

  &:focus {
    border-color: ${(props) => props.theme.black.tertiary};
  }

  &:last-child {
    margin-right: 0px;
  }
`;

interface SelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  width?: string | number;
  maxWidth?: string | number;
}

const Select = forwardRef<HTMLSelectElement, SelectProps>((props, ref) => {
  const { width, maxWidth, ...rest } = props;
  return <StyledSelect ref={ref} width={width} maxWidth={maxWidth} {...rest} />;
});

Select.displayName = "Select";

export default Select;
