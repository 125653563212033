import styled from "styled-components";

export const BoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  align-items: center;
  width: 100%;
`;

export const BoxTitle = styled.h1`
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 24px;
`;

export const BoxContent = styled.div`
  width: 100%;
  /* max-width: 1500px; */
  height: 100%;
  border: 1px solid ${(props) => props.theme.gray.main};
  border-radius: 4px;
  padding: 40px;
  position: relative;
`;

export const EachFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  margin-bottom: 24px;
`;

export const EachFormTitle = styled.h3`
  width: 100%;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 12px;
`;

export const EachFormContent = styled.div`
  width: 100%;
  font-size: 16px;
`;

export const ErrorMessage = styled.span`
  color: ${(props) => props.theme.red};
  margin-bottom: 8px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 24px;

  & > button:not(:last-child) {
    margin-right: 16px;
  }
`;
