import qs from "qs";
import { AxiosResponse } from "axios";
import { instanceWithAuth } from "../axios";
import {
  IApiResponse,
  IAdminAccountsBase,
  IAdminAccounts,
  IResponsePage,
} from "../../types/index";

function get_admin_accounts(
  fetchData: any
): Promise<AxiosResponse<IApiResponse<IResponsePage<IAdminAccounts>>>> {
  return instanceWithAuth.get(`/api/admin/list`, {
    params: fetchData,
    paramsSerializer: (params: any) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  });
}

function get_ehr_accounts(
  keyword: string
): Promise<AxiosResponse<IApiResponse<IAdminAccountsBase[]>>> {
  return instanceWithAuth.get(`/api/admin/ehrSearch`, {
    params: {
      keyword,
    },
    paramsSerializer: (params: any) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  });
}

function add_admin_accounts(
  params: IAdminAccountsBase
): Promise<AxiosResponse<IApiResponse<IAdminAccountsBase>>> {
  const { empId, empNm, email, password, corpCd, corpNm, orgId, orgNm } =
    params;
  return instanceWithAuth.post(`/api/admin/ehr/save`, {
    empId,
    empNm,
    email,
    password,
    corpCd,
    corpNm,
    orgId,
    orgNm,
  });
}

function delete_admin_account(
  adminId?: number
): Promise<AxiosResponse<IApiResponse<null>>> {
  return instanceWithAuth.put(`/api/admin/${adminId}/delete`);
}

export {
  get_admin_accounts,
  get_ehr_accounts,
  add_admin_accounts,
  delete_admin_account,
};
