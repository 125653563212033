import Header from "./components/Header";
import { Outlet, useLocation, Navigate } from "react-router-dom";
import { getCookie } from "./apis/cookie";
import styled from "styled-components";

const AppContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
`;

function App() {
  const accessToken = getCookie("accessToken");
  const location = useLocation();
  const hideHeaderPaths = ["/login", "/signup"];
  const shouldShowHeader = !hideHeaderPaths.includes(location.pathname);
  const contentMargin = location.pathname === "/login" ? "0" : "112px 40px";

  return (
    <AppContainer>
      {shouldShowHeader && <Header />}
      <ContentContainer style={{ padding: contentMargin }}>
        {location.pathname !== "/login" && accessToken === null ? (
          <Navigate to="/login" />
        ) : (
          <Outlet />
        )}
      </ContentContainer>
    </AppContainer>
  );
}

export default App;
