import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useForm } from "react-hook-form";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Button from "../../components/Button";
import InputText from "../../components/InputText";
import Select from "../../components/Select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/locale";
import dayjs from "dayjs";
import {
  BoxContainer,
  BoxTitle,
  BoxContent,
  EachFormWrapper,
  EachFormTitle,
  EachFormContent,
  ErrorMessage,
  ButtonWrapper,
} from "../../components/BoxSlot";
import Loading from "../../components/Loading";
import JoditEditor from "../../components/JoditEditor";
import {
  ITemplate,
  IUserGroupList,
  ICampaign,
  IResponsePage,
} from "../../types";
import {
  getTemplatesRequest,
  getTemplateRequest,
} from "../../utils/hooks/template";
import { getUserGroupsRequest } from "../../utils/hooks/userGroup";
import {
  getCampaignRequest,
  editCampaignRequest,
  deleteCampaignRequest,
  sendCampaignTestMailRequest,
} from "../../utils/hooks/campaign";
import { domainArr } from "../../utils/domain";

function EditCampaign() {
  const navigate = useNavigate();
  const { campaignId } = useParams();
  const [searchParams] = useSearchParams();
  const currentPage = searchParams.get("page") || "1";
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<ICampaign>();

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [templateId, setTemplateId] = useState("");
  const [mailSender] = useState<string>("Alfa-del@aju.co.kr");
  const [templateMain, setTemplateMain] = useState<string>("");
  const [templateDetail, setTemplateDetail] = useState<string>("");
  const [testMailAddress, setTestMailAddress] = useState("");

  const handleTemplateChange = async (e: any) => {
    setTemplateId(e.target.value);
    const data = await getTemplateRequest(Number(e.target.value));

    setTemplateMain(data?.templateMain || "");
    setTemplateDetail(data?.templateDetail || "");
  };

  const fetchData = {
    keyword: "",
    page: 0,
    size: 2000,
    sort: [""],
  };
  const { data: templates } = useQuery<IResponsePage<ITemplate>>(
    "getTemplateData",
    () => getTemplatesRequest(fetchData)
  );

  const { data: userGroups } = useQuery<IResponsePage<IUserGroupList>>(
    "userGroupData",
    () => getUserGroupsRequest(fetchData)
  );

  const {
    data: campaignData,
    isLoading,
    refetch: refetchCampaign,
  } = useQuery<ICampaign | undefined>(
    ["campaignData", campaignId],
    () => getCampaignRequest(Number(campaignId)),
    { enabled: false, refetchOnMount: false }
  );

  useEffect(() => {
    refetchCampaign();
  }, [campaignId, refetchCampaign]);

  useEffect(() => {
    if (campaignData) {
      setStartDate(new Date(campaignData.startDate));
      setEndDate(new Date(campaignData.endDate));

      setValue("campaignName", campaignData.campaignName);
      setValue("campaignMailDomain", campaignData.campaignMailDomain);
      setValue("templateId", campaignData.templateId);
      setValue("userGroupId", campaignData.userGroupId);
      setTemplateMain(campaignData.campaignTemplateMain || "");
      setTemplateDetail(campaignData.campaignTemplateDetail || "");
    }
  }, [campaignData, setValue]);

  const onSubmit = async (formData: ICampaign) => {
    console.log(formData);
    const fetchData = {
      ...formData,
      startDate: dayjs(startDate).format("YYYY-MM-DD") + "T00:00:00",
      endDate: dayjs(endDate).format("YYYY-MM-DD") + "T23:59:59",
      mailSender,
      templateMain,
      templateDetail,
    };
    const isConfirmed = window.confirm("캠페인을 수정 하시겠습니까?");
    if (isConfirmed) {
      try {
        const result = await editCampaignRequest(Number(campaignId), fetchData);
        if (result) {
          navigate(`/campaign?page=${currentPage}`);
        }
      } catch (error) {
        console.error("Error updating campaign:", error);
      }
    }
  };

  const deleteCampaign = async () => {
    const isConfirmed = window.confirm("캠페인을 삭제 하시겠습니까?");
    if (isConfirmed) {
      const { data } = await deleteCampaignRequest(Number(campaignId));
      if (data) {
        navigate(`/campaign?page=${currentPage}`);
      }
    }
  };

  const handleTestMail = async () => {
    if (window.confirm("캠페인 수정을 완료 하셨습니까?")) {
      const result = await sendCampaignTestMailRequest(
        Number(campaignId),
        testMailAddress
      );

      if (result === 200) {
        alert("테스트 메일 발송이 완료되었습니다.");
        return;
      } else {
        alert("테스트 메일 발송중 에러가 발생했습니다.");
      }
    }
  };

  const handleValueChange = (newMainContent: string) => {
    setTemplateMain(newMainContent);
  };

  const handleDetailChange = (newDetailContent: string) => {
    setTemplateDetail(newDetailContent);
  };

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <BoxContainer>
          <BoxTitle>캠페인 수정</BoxTitle>

          <BoxContent>
            <form onSubmit={handleSubmit(onSubmit)}>
              <EachFormWrapper>
                <EachFormTitle>캠페인 제목</EachFormTitle>
                <EachFormContent>
                  <InputText
                    {...register("campaignName", {
                      required: "캠페인 제목은 필수입니다.",
                    })}
                    placeholder="캠페인 제목"
                    width={"600px"}
                    type="text"
                    disabled={campaignData?.status !== "0" ? true : false}
                  />
                  {errors.campaignName && (
                    <ErrorMessage>{errors.campaignName.message}</ErrorMessage>
                  )}
                </EachFormContent>
              </EachFormWrapper>

              <EachFormWrapper>
                <EachFormTitle>도메인 선택</EachFormTitle>

                <EachFormContent>
                  <form>
                    <Select
                      {...register("campaignMailDomain", {
                        required: "도메인 선택은 필수입니다.",
                      })}
                      width="600px"
                      // onChange={handleTemplateChange}
                      disabled={campaignData?.status !== "0" ? true : false}
                    >
                      <option value="">선택하세요</option>
                      {domainArr.map((domain: any, index: number) => (
                        <option key={index} value={domain.value}>
                          {domain.name}
                        </option>
                      ))}
                    </Select>
                  </form>
                  {errors.campaignMailDomain && (
                    <ErrorMessage>
                      {errors.campaignMailDomain.message}
                    </ErrorMessage>
                  )}
                </EachFormContent>
              </EachFormWrapper>

              <EachFormWrapper>
                <EachFormTitle>메일 템플릿 선택</EachFormTitle>
                <EachFormContent>
                  <Select
                    {...register("templateId", {
                      required: "템플릿 선택은 필수입니다.",
                    })}
                    width="600px"
                    onChange={handleTemplateChange}
                    disabled={campaignData?.status !== "0" ? true : false}
                  >
                    <option value="">선택하세요</option>
                    {templates?.content?.map((row: ITemplate) => (
                      <option key={row.templateId} value={row.templateId}>
                        {row.templateName}
                      </option>
                    ))}
                  </Select>
                  {errors.templateId && (
                    <ErrorMessage>{errors.templateId.message}</ErrorMessage>
                  )}
                </EachFormContent>
              </EachFormWrapper>

              <EachFormWrapper>
                <EachFormTitle>캠페인 기간</EachFormTitle>
                <EachFormContent>
                  <div style={{ display: "flex" }}>
                    <DatePicker
                      selected={startDate}
                      onChange={(date: Date) => setStartDate(date)}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      locale={ko}
                      dateFormat="yyyy-MM-dd"
                      customInput={
                        <InputText placeholder="시작일자" width={"200px"} />
                      }
                    />
                    <DatePicker
                      selected={endDate}
                      onChange={(date: Date) => setEndDate(date)}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                      locale={ko}
                      dateFormat="yyyy-MM-dd"
                      customInput={
                        <InputText placeholder="종료일자" width={"200px"} />
                      }
                    />
                  </div>
                </EachFormContent>
              </EachFormWrapper>

              <EachFormWrapper>
                <EachFormTitle>훈련 대상 검색</EachFormTitle>
                <EachFormContent>
                  <Select
                    {...register("userGroupId", {
                      required: "훈련 대상 선택은 필수입니다.",
                    })}
                    width="600px"
                    disabled={campaignData?.status !== "0" ? true : false}
                  >
                    <option value="">선택하세요</option>
                    {userGroups?.content?.map((row: IUserGroupList) => (
                      <option key={row.userGroupId} value={row.userGroupId}>
                        {row.userGroupName}
                      </option>
                    ))}
                  </Select>
                  {errors.userGroupId && (
                    <ErrorMessage>{errors.userGroupId.message}</ErrorMessage>
                  )}
                </EachFormContent>
              </EachFormWrapper>

              <EachFormWrapper>
                <EachFormTitle>메일 주소 (보내는 사람)</EachFormTitle>
                <EachFormContent>
                  <InputText
                    value={mailSender}
                    placeholder="메일 주소 (보내는 사람)"
                    width={"600px"}
                    type="text"
                    disabled
                  />
                </EachFormContent>
              </EachFormWrapper>

              <EachFormWrapper>
                <EachFormTitle>메일 템플릿 메인</EachFormTitle>
                <EachFormContent>
                  {templateMain !== null && (
                    <JoditEditor
                      defaultValue={templateMain || ""}
                      onChange={handleValueChange}
                      readOnly={campaignData?.status !== "0"}
                    />
                  )}
                </EachFormContent>
              </EachFormWrapper>

              <EachFormWrapper>
                <EachFormTitle>메일 템플릿 상세</EachFormTitle>
                <EachFormContent>
                  {templateDetail !== null && (
                    <JoditEditor
                      defaultValue={templateDetail || ""}
                      onChange={handleDetailChange}
                      readOnly={campaignData?.status !== "0"}
                    />
                  )}
                </EachFormContent>
              </EachFormWrapper>

              <EachFormWrapper>
                <EachFormTitle>테스트 메일 주소</EachFormTitle>
                <EachFormContent>
                  <InputText
                    value={testMailAddress}
                    onChange={(e) => setTestMailAddress(e.target.value)}
                    placeholder="테스트 메일 주소"
                    width={"600px"}
                    type="text"
                  />
                  <Button
                    className="filled"
                    type="button"
                    onClick={handleTestMail}
                  >
                    테스트 발송
                  </Button>
                </EachFormContent>
              </EachFormWrapper>

              <ButtonWrapper>
                <Button
                  className="filled"
                  $bgColor="#e4e4e4"
                  $hoverBgColor="#f4f4f4"
                  color="#000"
                  onClick={(e) => {
                    navigate(`/campaign?page=${currentPage}`);
                    e.preventDefault();
                  }}
                >
                  취소
                </Button>
                <Button
                  className="filled"
                  $bgColor="#0c51af"
                  $hoverBgColor="#007bff"
                  type="submit"
                >
                  수정
                </Button>
                <Button
                  className="filled"
                  $bgColor="#ff3b30"
                  $hoverBgColor="#ff5c57"
                  onClick={(e) => {
                    deleteCampaign();
                    e.preventDefault();
                  }}
                >
                  삭제
                </Button>
              </ButtonWrapper>
            </form>
          </BoxContent>
        </BoxContainer>
      )}
    </>
  );
}

export default EditCampaign;
