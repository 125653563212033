import styled from "styled-components";
import { BallTriangle } from "react-loader-spinner";

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  margin: -112px -40px;
`;

const LoadingMessage = styled.span`
  margin-top: 20px;
`;

function Loading({ message = "로딩 중입니다..." }) {
  return (
    <LoadingWrapper>
      <BallTriangle
        height={100}
        width={100}
        radius={5}
        color="#0c51af"
        ariaLabel="ball-triangle-loading"
        visible={true}
      />
      <LoadingMessage>{message}</LoadingMessage>
    </LoadingWrapper>
  );
}

export default Loading;
