import styled from "styled-components";

export const TableTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin-bottom: 12px;
`;

export const SearchContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  width: auto;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  position: relative;
`;

export const TableHeader = styled.th<{ width?: string }>`
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  width: ${(props) => props.width || "auto"};
`;

export const TableCell = styled.td<{ width?: string }>`
  border: 1px solid #ddd;
  padding: 0 8px;
  height: 48px;
  cursor: pointer;
  width: ${(props) => props.width || "auto"};
`;

export const ScrollableTable = styled.div<{ height?: string }>`
  overflow-y: auto;
  max-height: ${(props) => props.height || "auto"};
  margin-top: 16px;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #c4c4c5;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #b4b4b5;
  }
`;
