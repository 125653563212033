import styled from "styled-components";
import { ThreeDots } from "react-loader-spinner";

const TableLoadingWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
  /* background-color: #fff;
  opacity: 0.4; */
  background-color: rgba(255, 255, 255, 0.4);
  z-index: 100;
  margin-top: -30px;
`;

const LoadingMessage = styled.span`
  /* margin-top: 10px; */
`;

function Loading({ message = "체크박스 토글 중입니다..." }) {
  return (
    <TableLoadingWrapper>
      <ThreeDots
        height={80}
        width={80}
        radius={9}
        color="#0c51af"
        ariaLabel="three-dots-loading"
        visible={true}
      />
      <LoadingMessage>{message}</LoadingMessage>
    </TableLoadingWrapper>
  );
}

export default Loading;
