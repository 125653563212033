import { createBrowserRouter } from "react-router-dom";
import App from "./App";

import Template from "./views/template/Template";
import CreateTemplate from "./views/template/CreateTemplate";
import EditTemplate from "./views/template/EditTemplate";

import Campaign from "./views/campaign/Campaign";
import CreateCampaign from "./views/campaign/CreateCampaign";
import EditCampaign from "./views/campaign/EditCampaign";
import CampaignResult from "./views/campaign/CampaignResult";

import UserGroup from "./views/userGroup/UserGroup";
import EditUserGroup from "./views/userGroup/EditUserGroup";

import AdminManage from "./views/adminAccount/AdminAccount";
import Login from "./views/Login";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "",
        element: <Template />,
      },
      {
        path: "template",
        element: <Template />,
      },
      {
        path: "template/create",
        element: <CreateTemplate />,
      },
      {
        path: "template/edit/:templateId",
        element: <EditTemplate />,
      },
      {
        path: "campaign",
        element: <Campaign />,
      },
      {
        path: "campaign/create",
        element: <CreateCampaign />,
      },
      {
        path: "campaign/edit/:campaignId",
        element: <EditCampaign />,
      },
      {
        path: "campaign/result/:campaignId",
        element: <CampaignResult />,
      },
      {
        path: "userGroup",
        element: <UserGroup />,
      },
      {
        path: "userGroup/edit/:userGroupId",
        element: <EditUserGroup />,
      },
      {
        path: "adminManage",
        element: <AdminManage />,
      },
      {
        path: "adminManage/create",
        element: <AdminManage />,
      },
      {
        path: "login",
        element: <Login />,
      },
    ],
  },
]);

export default router;
