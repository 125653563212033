import styled from "styled-components";
import { motion, useAnimation } from "framer-motion";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { IconDotsVertical } from "@tabler/icons-react";
import Button from "./Button";
import { useState, useEffect, useRef } from "react";

const Nav = styled(motion.nav)`
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  padding-left: 40px;
  padding-right: 40px;
  width: 100%;
  height: 80px;
  background-color: #eee;
  font-size: 14px;
  z-index: 1000;
  box-shadow: 0 2px 6px 0 rgba(29, 29, 31, 0.16);
`;

const Left = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 22px;
  font-weight: 700;
`;

const Right = styled.ul`
  display: flex;
  align-items: center;
`;

const Menu = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 40px;
  color: ${(props) => props.theme.black.secondary};
  font-weight: 700;
  transition: color 0.3s ease-in-out;
  &:hover {
    color: ${(props) => props.theme.black.tertiary};
  }
`;

const Admin = styled.li`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 20px;
  color: ${(props) => props.theme.black.secondary};
  transition: color 0.3s ease-in-out;
  border-radius: 16px;
  border: solid 1px rgba(29, 29, 31, 0.8);
  padding: 5px 14px 5px 18px;
  height: 32px;
  background-color: rgba(255, 255, 255, 0.5);

  &:hover {
    cursor: pointer;
  }

  svg {
    margin-left: 4px;
  }
`;

const AccountBox = styled(motion.div)`
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 100px;
  overflow: hidden;
  transition: max-height 200ms ease-in-out, padding 200ms ease-in-out;
  border-radius: 4px;
  box-shadow: 2px 4px 8px 0 rgba(29, 29, 31, 0.24);
  background-color: #fff;
`;

const Circle = styled(motion.span)`
  position: absolute;
  bottom: 24px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: ${(props) => props.theme.blue};
`;

function Header() {
  const location = useLocation();
  const accountBoxRef = useRef<HTMLDivElement>(null);

  const templateMatch =
    location.pathname === "/" || location.pathname.includes("template");
  const campaignMatch = location.pathname.includes("campaign");
  const userGroupMatch = location.pathname.includes("userGroup");
  const adminMatch = location.pathname.includes("adminManage");

  const navigate = useNavigate();
  const [isAccountBoxVisible, setIsAccountBoxVisible] = useState(false);
  const [userName, setUserName] = useState("");

  const accountBoxAnimation = useAnimation();
  const handleAdminClick = () => {
    if (isAccountBoxVisible) {
      accountBoxAnimation.start({ height: 0 });
    } else {
      accountBoxAnimation.start({ height: 100 });
    }
    setIsAccountBoxVisible((prev) => !prev);
  };

  const onLogout = () => {
    window.localStorage.clear();
    document.cookie = "accessToken=";
    document.cookie = "refreshToken=";
    navigate("/login");
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      accountBoxRef.current &&
      !accountBoxRef.current.contains(event.target as Node)
    ) {
      accountBoxAnimation.start({ height: 0 });
      setIsAccountBoxVisible(false);
    }
  };

  useEffect(() => {
    if (isAccountBoxVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isAccountBoxVisible]);

  useEffect(() => {
    const userInfo = localStorage.getItem("userInfo");
    if (userInfo) {
      const parsedUserInfo = JSON.parse(userInfo);
      setUserName(parsedUserInfo.empNm);
    }
  }, []);

  return (
    <>
      <Nav>
        <Left>
          <Link to="/">피싱 메일 관리 시스템</Link>
        </Left>

        <Right>
          <Menu>
            <Link to="/template">
              템플릿 관리 {templateMatch && <Circle layoutId="circle" />}
            </Link>
          </Menu>
          <Menu>
            <Link to="/userGroup">
              사용자 그룹 관리 {userGroupMatch && <Circle layoutId="circle" />}
            </Link>
          </Menu>
          <Menu>
            <Link to="/campaign">
              캠페인 관리 {campaignMatch && <Circle layoutId="circle" />}
            </Link>
          </Menu>
          <Menu>
            <Link to="/adminManage">
              관리자 계정 관리 {adminMatch && <Circle layoutId="circle" />}
            </Link>
          </Menu>
          <Admin onClick={handleAdminClick}>
            {userName}
            <IconDotsVertical stroke={2} size={16} />
            <AccountBox
              ref={accountBoxRef}
              initial={{ height: 0 }}
              animate={accountBoxAnimation}
            >
              <Button className="outlined" onClick={onLogout}>
                로그아웃
              </Button>
            </AccountBox>
          </Admin>
        </Right>
      </Nav>
    </>
  );
}

export default Header;
