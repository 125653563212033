import axios from "axios";
import { setInterceptors } from "./interceptors";

const baseURL = process.env.REACT_APP_API_URL;

/** axios 객체 생성 (인증 불필요) */
function createInstance() {
  return axios.create({
    baseURL,
  });
}

/** axios 객체 생성 (인증 필요) */
function createInstanceWithAuth() {
  const instance = axios.create({
    baseURL,
  });
  return setInterceptors(instance);
}
export const instance = createInstance();
export const instanceWithAuth = createInstanceWithAuth();
