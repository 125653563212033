import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import JoditEditor from "../../components/JoditEditor";
import Button from "../../components/Button";
import InputText from "../../components/InputText";
import Loading from "../../components/Loading";
import {
  BoxContainer,
  BoxTitle,
  BoxContent,
  EachFormWrapper,
  EachFormTitle,
  EachFormContent,
  ButtonWrapper,
} from "../../components/BoxSlot";
import { ITemplate } from "../../types";
import {
  getTemplateRequest,
  editTemplateRequest,
  deleteTemplateRequest,
} from "../../utils/hooks/template";

function EditTemplate() {
  const navigate = useNavigate();
  const { templateId } = useParams();
  const [searchParams] = useSearchParams();
  const currentPage = searchParams.get("page") || "1";

  const [templateName, setTemplateName] = useState("");
  const [mailSender] = useState("Alfa-del@aju.co.kr");
  const [templateMain, setTemplateMain] = useState("");
  const [templateDetail, setTemplateDetail] = useState("");

  const { data, isLoading, refetch } = useQuery<ITemplate | undefined>(
    ["templateData", templateId],
    () => getTemplateRequest(Number(templateId)),
    { enabled: false, refetchOnMount: false }
  );

  const editTemplate = async () => {
    const fetchData: any = {
      templateName,
      mailSender,
      templateMain,
      templateDetail,
    };

    const isConfirmed = window.confirm("템플릿을 수정 하시겠습니까?");
    if (isConfirmed) {
      const { data } = await editTemplateRequest(Number(templateId), fetchData);

      if (data) {
        navigate(`/template?page=${currentPage}`);
      }
    }
  };

  const deleteTemplate = async () => {
    const isConfirmed = window.confirm("템플릿을 삭제 하시겠습니까?");
    if (isConfirmed) {
      const data = await deleteTemplateRequest(Number(templateId));
      if (data.statusCode === 400) {
        alert(`${data.errorCode}`);
        return;
      }
      if (data.statusCode === 200) {
        navigate(`/template?page=${currentPage}`);
      }
    }
  };

  useEffect(() => {
    refetch();
  }, [templateId]);

  useEffect(() => {
    if (data) {
      setTemplateName(data.templateName || "");
      setTemplateMain(data.templateMain || "");
      setTemplateDetail(data.templateDetail || "");
    }
  }, [data]);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <BoxContainer>
          <BoxTitle>메일 템플릿 수정</BoxTitle>
          <BoxContent>
            <EachFormWrapper>
              <EachFormTitle>메일 템플릿 제목</EachFormTitle>
              <EachFormContent>
                <InputText
                  placeholder="메일 템플릿 제목"
                  width={"600px"}
                  type="text"
                  value={templateName}
                  onChange={(e) => setTemplateName(e.target.value)}
                />
              </EachFormContent>
            </EachFormWrapper>

            <EachFormWrapper>
              <EachFormTitle>메일 주소 (보내는 사람)</EachFormTitle>
              <EachFormContent>
                <InputText
                  value={mailSender}
                  placeholder="메일 주소 (보내는 사람)"
                  width={"600px"}
                  type="text"
                  disabled
                />
              </EachFormContent>
            </EachFormWrapper>

            <EachFormWrapper>
              <EachFormTitle>메일 템플릿 메인</EachFormTitle>
              <EachFormContent>
                {templateMain !== null && (
                  <JoditEditor
                    defaultValue={templateMain || ""}
                    onChange={(content) => setTemplateMain(content)}
                  />
                )}
              </EachFormContent>
            </EachFormWrapper>

            <EachFormWrapper>
              <EachFormTitle>메일 템플릿 상세</EachFormTitle>
              <EachFormContent>
                {templateDetail !== null && (
                  <JoditEditor
                    defaultValue={templateDetail || ""}
                    onChange={(content) => setTemplateDetail(content)}
                  />
                )}
              </EachFormContent>
            </EachFormWrapper>
          </BoxContent>
          <ButtonWrapper>
            <Button
              className="filled"
              $bgColor="#e4e4e4"
              $hoverBgColor="#f4f4f4"
              color="#000"
              onClick={() => navigate(`/template?page=${currentPage}`)}
            >
              취소
            </Button>
            <Button
              className="filled"
              $bgColor="#0c51af"
              $hoverBgColor="#007bff"
              onClick={editTemplate}
            >
              수정
            </Button>
            <Button
              className="filled"
              $bgColor="#ff3b30"
              $hoverBgColor="#ff5c57"
              onClick={deleteTemplate}
            >
              삭제
            </Button>
          </ButtonWrapper>
        </BoxContainer>
      )}
    </>
  );
}

export default EditTemplate;
