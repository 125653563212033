import { request_login, request_logout } from "../../apis/modules/login";
import { reIssueFunc } from "../../apis/reissue";
import { ILogin, ILogout } from "../../types";

export const loginRequest = async (inputData: ILogin): Promise<any> => {
  try {
    const { data } = await request_login(inputData);
    if (data.statusCode === 200) {
      return data.data;
    }
  } catch (error: any) {
    if (error.response?.status === 401) {
      const status = await reIssueFunc();
      if (status === 200) {
        return loginRequest(inputData);
      }
    }
  }
  return [];
};

export const logoutRequest = async (inputData: ILogout): Promise<any> => {
  try {
    const { data } = await request_logout(inputData);
    if (data.statusCode === 200) {
      return data.data;
    }
  } catch (error: any) {
    if (error.response?.status === 401) {
      const status = await reIssueFunc();
      if (status === 200) {
        return logoutRequest(inputData);
      }
    }
  }
  return [];
};
