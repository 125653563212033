import qs from "qs";
import { AxiosResponse } from "axios";
import { instanceWithAuth } from "../axios";
import {
  IApiResponse,
  ICampaign,
  ICampaignBase,
  ICampaignCurrent,
  ICampaignCurrentList,
  IResponsePage,
} from "../../types/index";

function get_campaigns(
  fetchData: any
): Promise<AxiosResponse<IApiResponse<IResponsePage<ICampaign>>>> {
  return instanceWithAuth.get(`/api/campaign/list`, {
    params: fetchData,
    paramsSerializer: {
      serialize: (params: any) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    },
  });
}

function create_campaign(
  params: ICampaignBase
): Promise<AxiosResponse<IApiResponse<ICampaignBase>>> {
  const {
    userGroupId,
    campaignName,
    startDate,
    endDate,
    templateId,
    mailSender,
    templateMain,
    templateDetail,
    campaignMailDomain,
  } = params;
  return instanceWithAuth.post(`/api/campaign/save`, {
    userGroupId,
    campaignName,
    startDate,
    endDate,
    templateId,
    mailSender,
    templateMain,
    templateDetail,
    campaignMailDomain,
  });
}

function get_campaign(
  campaignId: number
): Promise<AxiosResponse<IApiResponse<ICampaign>>> {
  return instanceWithAuth.get(`/api/campaign/${campaignId}`);
}

function edit_campaign(
  campaignId: number,
  params: ICampaign
): Promise<AxiosResponse<IApiResponse<ICampaign>>> {
  const {
    userGroupId,
    campaignName,
    startDate,
    endDate,
    templateId,
    mailSender,
    templateMain,
    templateDetail,
    campaignMailDomain,
  } = params;
  return instanceWithAuth.put(`/api/campaign/${campaignId}/save`, {
    userGroupId,
    campaignName,
    startDate,
    endDate,
    templateId,
    mailSender,
    campaignTemplateMain: templateMain,
    campaignTemplateDetail: templateDetail,
    campaignMailDomain,
  });
}

function delete_campaign(
  campaignId: number
): Promise<AxiosResponse<IApiResponse<null>>> {
  return instanceWithAuth.put(`/api/campaign/${campaignId}/delete`);
}

function get_campaign_current(
  campaignId: number,
  fetchData: any
): Promise<
  AxiosResponse<IApiResponse<ICampaignCurrent<ICampaignCurrentList>>>
> {
  return instanceWithAuth.get(`/api/campaignCurrent/${campaignId}/list`, {
    params: fetchData,
    paramsSerializer: {
      serialize: (params: any) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    },
  });
}

function get_campaign_current_excel(
  campaignId: number,
  campaignRoundNum: number
) {
  return instanceWithAuth.get(`/api/excel/campaignCurrent`, {
    params: { campaignId, campaignRoundNum },
    paramsSerializer: {
      serialize: (params: any) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    },
    responseType: "blob",
  });
}

function add_campaign_round(
  campaignId: number,
  campaignCurrentIncreaseRequest: any
) {
  return instanceWithAuth.post(`/api/campaignCurrent/${campaignId}/increase`, {
    campaignCurrentIncreaseRequest,
  });
}

function send_campaign_mail(
  campaignId: number,
  campaignRoundNum: number,
  campaignCurrentIncreaseRequest: any
) {
  return instanceWithAuth.post(
    `/api/campaignCurrent/${campaignId}/sendMail/${campaignRoundNum}`,
    {
      campaignCurrentIncreaseRequest,
      paramsSerializer: {
        serialize: (params: any) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      },
    }
  );
}

function send_campaign_test_mail(campaignId: number, recipientAddress: string) {
  return instanceWithAuth.post(`/api/campaign/${campaignId}/sendMail`, {
    recipientAddress,
  });
}

function copy_user_group(campaignCurrentIncreaseRequest: any) {
  return instanceWithAuth.post(`/api/campaignCurrent/userGroupCopy`, {
    campaignCurrentIncreaseRequest,
  });
}

export {
  get_campaigns,
  create_campaign,
  get_campaign,
  edit_campaign,
  delete_campaign,
  get_campaign_current,
  get_campaign_current_excel,
  add_campaign_round,
  send_campaign_mail,
  send_campaign_test_mail,
  copy_user_group,
};
