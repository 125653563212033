import { instanceWithAuth } from "./axios";

/** Access Token 재발급 요청을 보내는 함수 */
const fetch_reissue = () => {
  return instanceWithAuth.post("/api/auth/reissue");
};

/** Access Token을 재발급하고 쿠키에 저장하는 함수 */
const reIssueFunc = async () => {
  try {
    const { data } = await fetch_reissue();
    if (data.statusCode === 200) {
      const accessToken = data.data.accessToken;
      document.cookie = `accessToken=${accessToken}`;
      return data.statusCode;
    }
  } catch (error: any) {
    window.location.href = "/login";
    return error.response.status;
  }
};

export { fetch_reissue, reIssueFunc };
