import {
  get_admin_accounts,
  get_ehr_accounts,
  add_admin_accounts,
  delete_admin_account,
} from "../../apis/modules/adminAccount";
import { reIssueFunc } from "../../apis/reissue";
import { IAdminAccountsBase, IAdminAccounts, IResponsePage } from "../../types";

export const getAdminAccountsRequest = async (
  fetchData: any
): Promise<IResponsePage<IAdminAccounts>> => {
  try {
    const { data } = await get_admin_accounts(fetchData);
    if (data.statusCode === 200) {
      return {
        content: data.data.content,
        totalElements: data.data.totalElements,
        size: data.data.size,
        totalPages: data.data.totalPages,
        last: data.data.last,
      };
    }
  } catch (error: any) {
    if (error.response?.status === 401) {
      const status = await reIssueFunc();
      if (status === 200) {
        return getAdminAccountsRequest(fetchData);
      }
    }
  }
  return {
    content: [],
    totalElements: 0,
    size: 0,
    totalPages: 0,
    last: false,
  };
};

export const getEhrAccountsRequest = async (
  keyword: string
): Promise<IAdminAccountsBase[]> => {
  try {
    const { data } = await get_ehr_accounts(keyword);
    if (data.statusCode === 200) {
      return data.data;
    }
  } catch (error: any) {
    if (error.response?.status === 401) {
      const status = await reIssueFunc();
      if (status === 200) {
        return getEhrAccountsRequest(keyword);
      }
    }
  }
  return [];
};

export const addAdminAccountsRequest = async (
  inputData: IAdminAccountsBase
): Promise<any> => {
  try {
    const { data } = await add_admin_accounts(inputData);
    if (data.statusCode === 200) {
      return data.data;
    }
  } catch (error: any) {
    if (error.response?.status === 400) {
      return error?.response?.data;
    }
    if (error.response?.status === 401) {
      const status = await reIssueFunc();
      if (status === 200) {
        return addAdminAccountsRequest(inputData);
      }
    }
  }
  return [];
};

export const deleteAdminAccountRequest = async (
  adminId: number
): Promise<any> => {
  try {
    const { data } = await delete_admin_account(adminId);
    if (data.statusCode === 200) {
      return data;
    }
  } catch (error: any) {
    if (error.response?.status === 401) {
      const status = await reIssueFunc();
      if (status === 200) {
        return deleteAdminAccountRequest(adminId);
      }
    }
  }
  return [];
};
