import React from "react";
import ReactDOM from "react-dom/client";
import { ThemeProvider } from "styled-components";
import { GlobalStyle } from "./styles/globalStyle";
import { theme } from "./styles/theme";
import { QueryClient, QueryClientProvider } from "react-query";
import { RouterProvider } from "react-router-dom";
import router from "./Router";
// import { Provider } from "react-redux";
// import store from "./stores";
import "./index.css";

const client = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={client}>
      <ThemeProvider theme={theme}>
        {/* <Provider store={store}> */}
        <GlobalStyle />
        <RouterProvider router={router} />
        {/* </Provider> */}
      </ThemeProvider>
    </QueryClientProvider>
  </React.StrictMode>
);
