import { AxiosResponse } from "axios";
import { instance } from "../axios";
import { IApiResponse, ILogin, ILogout } from "../../types/index";

function request_login(
  params: ILogin
): Promise<AxiosResponse<IApiResponse<any>>> {
  const { adminId, password } = params;
  return instance.post(
    "/api/auth/login",
    { adminId, password },
    { withCredentials: true }
  );
}

function request_logout(
  params: ILogout
): Promise<AxiosResponse<IApiResponse<any>>> {
  const { Authorization, refreshToken } = params;
  return instance.post(
    "/api/auth/logout",
    { Authorization, refreshToken },
    { withCredentials: true }
  );
}

export { request_login, request_logout };
