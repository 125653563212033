import { DefaultTheme } from "styled-components";

export const theme: DefaultTheme = {
  black: {
    primary: "#1d1d1f",
    secondary: "#4a4a4b",
    tertiary: "#777778",
  },
  gray: {
    light: "#f4f4f4",
    main: "#e4e4e4",
  },
  blue: "rgb(12, 81, 175)",
  red: "#e03a3e",
};
