import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useForm } from "react-hook-form";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import styled from "styled-components";
import InputText from "../../components/InputText";
import Button from "../../components/Button";
import Loading from "../../components/Loading";
import TableLoading from "../../components/TableLoading";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/locale";
import dayjs from "dayjs";
import {
  BoxContainer,
  BoxTitle,
  BoxContent,
  EachFormWrapper,
  EachFormTitle,
  EachFormContent,
  ButtonWrapper,
  ErrorMessage,
} from "../../components/BoxSlot";
import {
  TableTop,
  SearchContainer,
  Table,
  TableHeader,
  TableCell,
  ScrollableTable,
} from "../../components/TableSlot";
import {
  IUserGroup,
  IUserGroupCount,
  IUserGroupTarget,
  IUserGroupYn,
} from "../../types";
import {
  getUserGroupRequest,
  getUserGroupCountRequest,
  toggleUserGroupRequest,
  editUserGroupRequest,
  deleteUserGroupRequest,
  getUserGroupResultExcelRequest,
} from "../../utils/hooks/userGroup";

const ResultBox = styled.div`
  border: 1px solid ${(props) => props.theme.black.secondary};
  border-radius: 8px;
  padding: 8px 16px;

  & > span:not(:last-child) {
    margin-right: 16px;
  }
`;

const ResultCount = styled.span`
  color: ${(props) => props.theme.red};
  font-weight: 700;
`;

const CustomCheckbox = styled.input`
  width: 20px;
  height: 20px;
  /* position: relative; */
  /* z-index: 1; */
  margin-left: 8px;
  margin-right: 8px;
  /* z-index: 10; */
`;

function EditUserGroup() {
  const { state } = useLocation();
  const initialUserGroupName = state?.userGroupName || "";

  const [corpNmKeyword, setCorpNmKeyword] = useState<string>("");
  const [comNmKeyword, setComNmKeyword] = useState<string>("");
  const [orgNmKeyword, setOrgNmKeyword] = useState<string>("");
  const [dutyNmKeyword, setDutyNmKeyword] = useState<string>("");
  const [empNmKeyword, setEmpNmKeyword] = useState<string>("");
  const [emailCheck, setEmailCheck] = useState<string>("");

  const [joinStartDate, setJoinStartDate] = useState(new Date("1900-01-01"));
  const [joinEndDate, setJoinEndDate] = useState(new Date());

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const { userGroupId } = useParams();
  const [searchParams] = useSearchParams();
  const currentPage = searchParams.get("page") || "1";
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IUserGroup>({
    defaultValues: {
      userGroupName: initialUserGroupName,
    },
  });
  const fetchData = {
    corpNmKeyword,
    comNmKeyword,
    orgNmKeyword,
    dutyNmKeyword,
    empNmKeyword,
    emailCheck,
    joinStartDate: dayjs(joinStartDate).format("YYYY-MM-DD") + "T00:00:00",
    joinEndDate: dayjs(joinEndDate).format("YYYY-MM-DD") + "T23:59:59",
  };
  const {
    data: userGroupData,
    isLoading: userGroupLoading,
    refetch: refetchUserGroupData,
  } = useQuery(
    ["userGroupData", userGroupId],
    () => getUserGroupRequest(Number(userGroupId), fetchData),
    {
      enabled: false,
      refetchOnMount: false,
    }
  );
  const {
    data: userGroupCountData,
    isLoading: userCountLoading,
    refetch: refetchuserGroupCountData,
  } = useQuery<IUserGroupCount>(
    "userGroupCountData",
    () => getUserGroupCountRequest(Number(userGroupId)),
    {
      enabled: false,
      refetchOnMount: false,
    }
  );

  const toggleUserGroup = async (
    userGroupDetails: { userGroupDetailId: number; useYn: string }[]
  ) => {
    const fetchData = userGroupDetails.map(({ userGroupDetailId, useYn }) => ({
      userGroupDetailId,
      useYn,
    }));

    const result = await toggleUserGroupRequest(Number(userGroupId), fetchData);
    if (result) {
      refetchUserGroupData();
      refetchuserGroupCountData();
    }
  };

  const splitFileName = (contentDisposition: string) => {
    const fileName = contentDisposition.split(";").filter((ele) => {
      return ele.indexOf("filename") > -1;
    })[0];

    if (fileName) {
      const encodedFileName = fileName.split("''")[1];
      return decodeURIComponent(encodedFileName);
    }

    return "default.xlsx";
  };

  const handleExcelExport = async () => {
    const res = await getUserGroupResultExcelRequest(Number(userGroupId));

    const fileNameWithKeyValue = splitFileName(
      res.headers["content-disposition"]
    );
    const fileName = fileNameWithKeyValue || "default.xlsx";

    const blob = new Blob([res.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const link = document.createElement("a");
    const blobUrl = URL.createObjectURL(blob);
    link.href = blobUrl;
    link.download = fileName;
    link.click();
    URL.revokeObjectURL(blobUrl);

    return res.data;
  };

  const handleSearchInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    const value = e.target.value;

    switch (type) {
      case "corpNm":
        setCorpNmKeyword(value);
        break;
      case "comNm":
        setComNmKeyword(value);
        break;
      case "orgNm":
        setOrgNmKeyword(value);
        break;
      case "dutyNm":
        setDutyNmKeyword(value);
        break;
      case "empNm":
        setEmpNmKeyword(value);
        break;
      default:
        break;
    }
  };

  const handleKeyDown = async (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
      await refetchUserGroupData();
    }
  };

  const handleToggleAll = async (checked: boolean) => {
    if (userGroupData) {
      const userGroupDetails = userGroupData.userGroupDetail.map(
        (row: IUserGroupYn) => ({
          userGroupDetailId: row.userGroupDetailId,
          useYn: checked ? "Y" : "N",
        })
      );
      setIsLoading(true); // Start the spinner
      await toggleUserGroup(userGroupDetails);
      setIsLoading(false); // Stop the spinner
    }
  };

  const handleToggle = async (
    userGroupDetailId: number,
    currentUseYn: string
  ) => {
    const useYn = currentUseYn === "Y" ? "N" : "Y";
    setIsLoading(true); // Start the spinner
    await toggleUserGroup([{ userGroupDetailId, useYn }]);
    setIsLoading(false); // Stop the spinner
  };

  const handleEmailCheckToggle = (checked: boolean) => {
    console.log(checked);
    setEmailCheck(checked ? "Y" : "");
  };

  useEffect(() => {
    refetchUserGroupData();
  }, [emailCheck, joinStartDate, joinEndDate, refetchUserGroupData]);

  const deleteUserGroup = async () => {
    const isConfirmed = window.confirm("사용자 그룹을 삭제 하시겠습니까?");
    if (isConfirmed) {
      const data = await deleteUserGroupRequest(Number(userGroupId));
      if (data.statusCode === 400) {
        alert(`${data.errorCode}`);
        return;
      }
      if (data.statusCode === 200) {
        navigate(`/userGroup?page=${currentPage}`);
      }
    }
  };

  const allChecked = userGroupData?.userGroupDetail?.every(
    (row: IUserGroupYn) => row.useYn === "Y"
  );

  useEffect(() => {
    refetchUserGroupData();
    refetchuserGroupCountData();
  }, [refetchUserGroupData, refetchuserGroupCountData, userGroupId]);

  useEffect(() => {
    if (initialUserGroupName) {
      setValue("userGroupName", initialUserGroupName);
    }
  }, [initialUserGroupName, setValue]);

  const onSubmit = async (formData: IUserGroup) => {
    const isConfirmed = window.confirm("사용자 그룹을 수정 하시겠습니까?");
    if (isConfirmed) {
      try {
        const result = await editUserGroupRequest(
          Number(userGroupId),
          formData
        );
        if (result) {
          navigate(`/userGroup?page=${currentPage}`);
        }
      } catch (error) {
        console.error("Error updating user group:", error);
      }
    }
  };

  return (
    <>
      {userGroupLoading || userCountLoading ? (
        <Loading />
      ) : (
        <BoxContainer>
          <BoxTitle>사용자 그룹 수정</BoxTitle>

          <BoxContent>
            <form onSubmit={handleSubmit(onSubmit)}>
              <EachFormWrapper>
                <EachFormTitle>사용자 그룹 제목</EachFormTitle>

                <EachFormContent>
                  <InputText
                    {...register("userGroupName", {
                      required: "사용자 그룹 제목은 필수입니다.",
                    })}
                    placeholder="사용자 그룹 제목"
                    width={"600px"}
                    type="text"
                  />
                  {errors.userGroupName && (
                    <ErrorMessage>{errors.userGroupName.message}</ErrorMessage>
                  )}
                </EachFormContent>
              </EachFormWrapper>

              <EachFormWrapper>
                <EachFormTitle>대상자</EachFormTitle>
                <EachFormContent>
                  <TableTop>
                    <SearchContainer>
                      <InputText
                        placeholder="회사명"
                        width="150px"
                        type="text"
                        value={corpNmKeyword}
                        onChange={(e) => handleSearchInputChange(e, "corpNm")}
                        onKeyDown={handleKeyDown}
                      />
                      <InputText
                        placeholder="본부명"
                        width="150px"
                        type="text"
                        value={comNmKeyword}
                        onChange={(e) => handleSearchInputChange(e, "comNm")}
                        onKeyDown={handleKeyDown}
                      />
                      <InputText
                        placeholder="부서명"
                        width="150px"
                        type="text"
                        value={orgNmKeyword}
                        onChange={(e) => handleSearchInputChange(e, "orgNm")}
                        onKeyDown={handleKeyDown}
                      />
                      <InputText
                        placeholder="직책"
                        width="150px"
                        type="text"
                        value={dutyNmKeyword}
                        onChange={(e) => handleSearchInputChange(e, "dutyNm")}
                        onKeyDown={handleKeyDown}
                      />
                      <InputText
                        placeholder="사원명"
                        width="150px"
                        type="text"
                        value={empNmKeyword}
                        onChange={(e) => handleSearchInputChange(e, "empNm")}
                        onKeyDown={handleKeyDown}
                      />

                      <div style={{ display: "flex" }}>
                        <DatePicker
                          selected={joinStartDate}
                          onChange={(date: Date) => setJoinStartDate(date)}
                          selectsStart
                          startDate={joinStartDate}
                          endDate={joinEndDate}
                          locale={ko}
                          dateFormat="yyyy-MM-dd"
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          customInput={
                            <InputText
                              placeholder="입사 시작일자"
                              width={"150px"}
                            />
                          }
                        />
                        <DatePicker
                          selected={joinEndDate}
                          onChange={(date: Date) => setJoinEndDate(date)}
                          selectsEnd
                          startDate={joinStartDate}
                          endDate={joinEndDate}
                          locale={ko}
                          dateFormat="yyyy-MM-dd"
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          todayButton="Today"
                          customInput={
                            <InputText
                              placeholder="입사 시작일자"
                              width={"150px"}
                            />
                          }
                        />
                      </div>
                    </SearchContainer>

                    <SearchContainer>
                      <CustomCheckbox
                        type="checkbox"
                        checked={emailCheck === "Y"}
                        onChange={(e) =>
                          handleEmailCheckToggle(e.target.checked)
                        }
                      />
                      이메일 없는 대상만 보기
                    </SearchContainer>
                  </TableTop>

                  <ScrollableTable height="300px">
                    {isLoading && <TableLoading />}
                    <Table>
                      <thead>
                        <tr
                          style={{
                            position: "sticky",
                            top: "0",
                            backgroundColor: "#fff",
                          }}
                        >
                          <TableHeader width="10%">
                            <CustomCheckbox
                              type="checkbox"
                              checked={allChecked}
                              onChange={(e) =>
                                handleToggleAll(e.target.checked)
                              }
                              style={{ zIndex: 10 }}
                            />
                            대상 여부
                          </TableHeader>
                          <TableHeader width="10%">회사명</TableHeader>
                          <TableHeader width="20%">본부명</TableHeader>
                          <TableHeader width="20%">부서명</TableHeader>
                          <TableHeader width="10%">직책</TableHeader>
                          <TableHeader width="10%">사원명</TableHeader>
                          <TableHeader width="10%">이메일</TableHeader>
                          <TableHeader width="10%">입사일</TableHeader>
                        </tr>
                      </thead>
                      <tbody>
                        {userGroupData?.userGroupDetail?.map(
                          (row: IUserGroupTarget, index: number) => (
                            <tr key={index}>
                              <TableCell width="10%">
                                <CustomCheckbox
                                  type="checkbox"
                                  checked={row.useYn === "Y"}
                                  onChange={() =>
                                    handleToggle(
                                      row.userGroupDetailId,
                                      row.useYn
                                    )
                                  }
                                />
                              </TableCell>
                              <TableCell width="20%">{row.corpNm}</TableCell>
                              <TableCell width="20%">{row.comNm}</TableCell>
                              <TableCell width="20%">{row.orgNm}</TableCell>
                              <TableCell width="10%">{row.dutyNm}</TableCell>
                              <TableCell width="10%">{row.empNm}</TableCell>
                              <TableCell width="10%">{row.email}</TableCell>
                              <TableCell width="10%">{row.joinYmd}</TableCell>
                            </tr>
                          )
                        )}
                      </tbody>
                    </Table>
                  </ScrollableTable>
                </EachFormContent>
              </EachFormWrapper>

              <EachFormWrapper>
                <EachFormTitle>결과</EachFormTitle>

                <EachFormContent>
                  <TableTop>
                    <ResultBox>
                      전체:
                      <ResultCount>
                        {userGroupCountData?.useAllCnt}명
                      </ResultCount>
                      대상 인원:
                      <ResultCount>
                        {userGroupCountData?.useYesCnt}명
                      </ResultCount>
                      제외 인원:
                      <ResultCount>
                        {userGroupCountData?.useNoCnt}명
                      </ResultCount>
                    </ResultBox>

                    <Button
                      className="filled"
                      $bgColor="#e4e4e4"
                      $hoverBgColor="#f4f4f4"
                      color="#000"
                      onClick={(e) => {
                        handleExcelExport();
                        e.preventDefault();
                      }}
                    >
                      전체 엑셀 다운로드
                    </Button>
                  </TableTop>
                </EachFormContent>
              </EachFormWrapper>

              <ButtonWrapper>
                <Button
                  className="filled"
                  $bgColor="#e4e4e4"
                  $hoverBgColor="#f4f4f4"
                  color="#000"
                  onClick={(e) => {
                    navigate(`/userGroup?page=${currentPage}`);
                    e.preventDefault();
                  }}
                >
                  취소
                </Button>
                <Button
                  className="filled"
                  $bgColor="#0c51af"
                  $hoverBgColor="#007bff"
                  type="submit"
                >
                  수정
                </Button>
                <Button
                  className="filled"
                  $bgColor="#ff3b30"
                  $hoverBgColor="#ff5c57"
                  onClick={(e) => {
                    deleteUserGroup();
                    e.preventDefault();
                  }}
                >
                  삭제
                </Button>
              </ButtonWrapper>
            </form>
          </BoxContent>
        </BoxContainer>
      )}
    </>
  );
}

export default EditUserGroup;
