import qs from "qs";
import { AxiosResponse } from "axios";
import { instanceWithAuth } from "../axios";
import {
  IUserGroup,
  IUserGroupList,
  IUserGroupEach,
  IUserGroupTarget,
  IUserGroupSearch,
  IUserGroupCount,
  IUserGroupYn,
  IApiResponse,
  IResponsePage,
} from "../../types/index";

function get_user_groups(
  fetchData: any
): Promise<AxiosResponse<IApiResponse<IResponsePage<IUserGroupList>>>> {
  return instanceWithAuth.get(`/api/userGroup/list`, {
    params: fetchData,
    paramsSerializer: (params: any) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  });
}

function create_user_group(): Promise<AxiosResponse<IApiResponse<null>>> {
  return instanceWithAuth.post(`/api/userGroup/save`, {});
}

function get_user_group(
  userGroupId: number,
  fetchData: IUserGroupSearch
): Promise<AxiosResponse<IApiResponse<IUserGroupEach<IUserGroupTarget>>>> {
  return instanceWithAuth.get(`/api/userGroup/${userGroupId}/userGroupDetail`, {
    params: fetchData,
  });
}

function get_user_group_count(
  userGroupId: number
): Promise<AxiosResponse<IApiResponse<IUserGroupCount>>> {
  return instanceWithAuth.get(
    `/api/userGroup/${userGroupId}/userGroupDetailCount`
  );
}

function toggle_user_group(
  userGroupId: number,
  userGroupUseYnRequest: IUserGroupYn[]
): Promise<AxiosResponse<IApiResponse<any>>> {
  return instanceWithAuth.put(
    `/api/userGroup/${userGroupId}/userGroupDetailYn`,
    {
      userGroupUseYnRequest,
    }
  );
}

function edit_user_group(
  userGroupId: number,
  fetchData: any
): Promise<AxiosResponse<IApiResponse<IUserGroup>>> {
  const { userGroupName } = fetchData;
  return instanceWithAuth.put(`/api/userGroup/${userGroupId}/save`, {
    userGroupName,
  });
}

function delete_user_group(
  userGroupId: number
): Promise<AxiosResponse<IApiResponse<null>>> {
  return instanceWithAuth.put(`/api/userGroup/${userGroupId}/delete`);
}

function get_user_group_result_excel(userGroupId: number) {
  return instanceWithAuth.get(`/api/excel/userGroup`, {
    params: {
      userGroupId,
    },
    paramsSerializer: {
      serialize: (params: any) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    },
    responseType: "blob",
  });
}

export {
  get_user_groups,
  create_user_group,
  get_user_group,
  get_user_group_count,
  toggle_user_group,
  edit_user_group,
  delete_user_group,
  get_user_group_result_excel,
};
