import qs from "qs";
import { AxiosResponse } from "axios";
import { instanceWithAuth } from "../axios";
import {
  IApiResponse,
  ITemplate,
  ITemplateBase,
  IResponsePage,
} from "../../types/index";

function get_templates(
  fetchData: any
): Promise<AxiosResponse<IApiResponse<IResponsePage<ITemplate>>>> {
  return instanceWithAuth.get(`/api/template/list`, {
    params: fetchData,
    paramsSerializer: {
      serialize: (params: any) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    },
  });
}

function create_template(
  params: ITemplateBase
): Promise<AxiosResponse<IApiResponse<ITemplateBase>>> {
  const { templateName, mailSender, templateMain, templateDetail } = params;
  return instanceWithAuth.post(`/api/template/save`, {
    templateName,
    mailSender,
    templateMain,
    templateDetail,
  });
}

function get_template(
  templateId: number
): Promise<AxiosResponse<IApiResponse<ITemplate>>> {
  return instanceWithAuth.get(`/api/template/${templateId}`);
}

function edit_template(
  templateId: number,
  params: ITemplate
): Promise<AxiosResponse<IApiResponse<ITemplate>>> {
  const { templateName, mailSender, templateMain, templateDetail } = params;
  return instanceWithAuth.put(`/api/template/${templateId}/save`, {
    templateName,
    mailSender,
    templateMain,
    templateDetail,
  });
}

function delete_template(
  templateId: number
): Promise<AxiosResponse<IApiResponse<null>>> {
  return instanceWithAuth.put(`/api/template/${templateId}/delete`);
}

export {
  get_templates,
  create_template,
  get_template,
  edit_template,
  delete_template,
};
