import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import InputText from "../../components/InputText";
import Button from "../../components/Button";
import Loading from "../../components/Loading";
import {
  TableTop,
  SearchContainer,
  Table,
  TableHeader,
  TableCell,
} from "../../components/TableSlot";
import Pagination from "../../components/Pagination";
import { IUserGroupList, IResponsePage } from "../../types";
import {
  getUserGroupsRequest,
  createUserGroupRequest,
} from "../../utils/hooks/userGroup";

const UserGroup = () => {
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const initialPage = parseInt(searchParams.get("page") || "1", 10);
  const [currentPage, setCurrentPage] = useState<number>(initialPage);
  const fetchData = {
    keyword: searchKeyword,
    page: currentPage - 1,
    size: 10,
    sort: [""],
  };
  const {
    data: userGroups = {
      content: [],
      totalElements: 0,
      size: 0,
      totalPages: 0,
      last: false,
    },
    isLoading,
    refetch,
  } = useQuery<IResponsePage<IUserGroupList>>(
    ["userGroupData", searchKeyword, currentPage],
    () => getUserGroupsRequest(fetchData),
    { keepPreviousData: true }
  );

  const mutation = useMutation(createUserGroupRequest, {
    onSuccess: () => {
      refetch();
      setIsCreating(false); // 요청이 성공하면 로딩 상태 해제
    },
    onError: () => {
      setIsCreating(false); // 요청이 실패해도 로딩 상태 해제
    },
  });

  const onCreateClick = async () => {
    const isConfirmed = window.confirm("새 사용자 그룹을 등록 하시겠습니까?");
    if (isConfirmed) {
      setIsCreating(true);
      mutation.mutate();
    }
  };

  const handleSearch = async () => {
    try {
      setCurrentPage(1);
      await refetch();
    } catch (error) {
      console.error("Error fetching template data:", error);
    }
  };

  const handleRowClick = (id: number, userGroupName: string) => {
    navigate(`/userGroup/edit/${id}?page=${currentPage}`, {
      state: { userGroupName },
    });
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
    navigate(`/userGroup?page=${pageNumber}`);
  };

  useEffect(() => {
    // 페이지가 로드될 때마다 데이터를 새로 불러옵니다.
    refetch();
  }, [currentPage, refetch]);

  return (
    <>
      {isLoading || isCreating ? (
        <Loading message="페이지를 불러오는 중입니다..." />
      ) : (
        <>
          <TableTop>
            <SearchContainer>
              <InputText
                placeholder="그룹 명을 입력하세요"
                width={"250px"}
                type="text"
                value={searchKeyword}
                onChange={(e) => setSearchKeyword(e.target.value)}
              />
              {/* <Button
                className="outlined"
                $borderColor="#0c51af"
                onClick={handleSearch}
              >
                검색
              </Button> */}
            </SearchContainer>
            <Button
              className="filled"
              $bgColor="#0c51af"
              $hoverBgColor="#007bff"
              onClick={onCreateClick}
            >
              등록
            </Button>
          </TableTop>

          <Table>
            <thead>
              <tr>
                <TableHeader width="20%">번호</TableHeader>
                <TableHeader width="50%">그룹 명</TableHeader>
                <TableHeader width="30%">대상 인원</TableHeader>
              </tr>
            </thead>
            <tbody>
              {userGroups?.content?.map(
                (row: IUserGroupList, index: number) => (
                  <tr
                    key={index}
                    onClick={() =>
                      handleRowClick(row.userGroupId, row.userGroupName)
                    }
                  >
                    <TableCell width="20%">
                      {" "}
                      {userGroups.totalElements -
                        ((currentPage - 1) * fetchData.size + index)}
                    </TableCell>
                    <TableCell width="50%">{row.userGroupName}</TableCell>
                    <TableCell width="30%">{row.useYesCnt}명</TableCell>
                  </tr>
                )
              )}
            </tbody>
          </Table>

          <Pagination
            page={currentPage}
            totalPageCount={userGroups.totalPages}
            pageDisplayCount={userGroups.size}
            onChange={handlePageChange}
          />
        </>
      )}
    </>
  );
};

export default UserGroup;
